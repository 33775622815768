import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./KeywordsSidebar.module.scss";
import { Loader } from "src/assets/icons";
import { useAppDispatch } from "src/store";
import { usePreviousState } from "src/hooks";
import { Button, Sidebar } from "src/components";
import { removeExtraSpaces, showToastNotification } from "src/utils";
import { getSearchSelectedKeywords } from "src/store/searches/searchesApi";
import {
  selectSearchById,
  selectTrackersWithSearchId,
  selectSearchConfigurationById,
  selectTrackersCollectionsWithSearchId,
} from "src/store/selectors";
import {
  updateSearch,
  updateTrackers,
  updateSearchConfiguration,
  updateTrackersCollections,
} from "src/store/actions";
import { KeywordsTable } from "../KeywordsTable/KeywordsTable";
import type { Keyword, SelectStatus } from "../KeywordsTable/types";
import { useSearchStatusObserver } from "../TrackerPageComponents/SelectedSearchesSection/hooks";

// Inner imports
import {
  getGroupedKeywords,
  getDuplicatedKeywords,
  getExactMatchKeywords,
  getExactMatchDuplicatedKeywords,
} from "./utils";
import { KeywordsControl, KeywordsInfo } from "./components";

type Props = {
  searchId: Search.Data["id"];
  keywordsData?: Search.KeywordsData;
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
};

export const KeywordsSidebar: FC<Props> = ({
  searchId,
  keywordsData,
  isOpened,
  setIsOpened,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const search = useSelector((state: Store.RootState) =>
    selectSearchById(state, searchId),
  );

  const trackersCollections = useSelector((state: Store.RootState) =>
    selectTrackersCollectionsWithSearchId(state, searchId),
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersWithSearchId(state, searchId),
  );

  const searchConfiguration = useSelector((state: Store.RootState) =>
    selectSearchConfigurationById(state, searchId),
  );

  const defaultSelectedKeywords = useMemo<Record<Keyword, true>>(() => {
    const searchSelectedKeywords = searchConfiguration?.keywords || [];

    const keywords = new Map<Keyword, true>();

    for (const keyword of searchSelectedKeywords) keywords.set(keyword, true);

    return Object.fromEntries(keywords);
  }, [searchConfiguration?.keywords]);

  const { searchStatus, isObserverSet } = useSearchStatusObserver(search);

  const [selectedKeywords, setSelectedKeywords] = useState<
    Record<Keyword, true>
  >(defaultSelectedKeywords);

  const [autoSelectedKeywords, setAutoSelectedKeywords] = useState<Record<
    Keyword,
    true
  > | null>(null);

  const [isKeywordsExactMatch, setIsKeywordsExactMatch] =
    useState<boolean>(true);

  const [isDuplicatedKeywordsShown, setIsDuplicatedKeywordsShown] =
    useState<boolean>(false);

  const [keywordsSearch, setKeywordsSearch] = useState<string>("");

  const [updateLoadingStatus, setUpdateLoadingStatus] =
    useState<LoadingStatus>("idle");

  const [keywordsSelectLoadingStatus, setKeywordsSelectLoadingStatus] =
    useState<LoadingStatus>("idle");

  const keywordsDataStatus = useMemo<LoadingStatus>(
    () => keywordsData?.status || "idle",
    [keywordsData?.status],
  );

  const previousKeywordsDataStatus = usePreviousState(keywordsDataStatus);

  const keywords = useMemo<Search.Keyword[]>(
    () => keywordsData?.keywords || [],
    [keywordsData?.keywords],
  );

  const isKeywordsLoading = useMemo<boolean>(
    () => keywordsDataStatus === "idle" || keywordsDataStatus === "loading",
    [keywordsDataStatus],
  );

  const isKeywordsPending = useMemo<boolean>(
    () => !isObserverSet || searchStatus === "PENDING",
    [searchStatus, isObserverSet],
  );

  const exactMatchKeywords = useMemo<Search.Keyword[]>(() => {
    if (!search) return keywords;

    return getExactMatchKeywords(keywords, search);
  }, [keywords, search]);

  const filteredKeywords = useMemo<Search.Keyword[]>(() => {
    if (!search || !isKeywordsExactMatch) return keywords;

    return exactMatchKeywords;
  }, [search, isKeywordsExactMatch, keywords, exactMatchKeywords]);

  const duplicatedKeywords = useMemo<Search.Keyword[]>(() => {
    if (!search || !isKeywordsExactMatch)
      return getDuplicatedKeywords(keywords);

    return getExactMatchDuplicatedKeywords(keywords, search);
  }, [isKeywordsExactMatch, keywords, search]);

  const searchedKeywords = useMemo<Search.Keyword[]>(() => {
    const keywords = isDuplicatedKeywordsShown
      ? duplicatedKeywords
      : filteredKeywords;

    if (!search || !keywordsSearch) return keywords;

    const formattedKeywordsSearch = removeExtraSpaces(
      keywordsSearch.toLowerCase(),
    );

    return keywords.filter(({ string }) =>
      removeExtraSpaces(string.toLowerCase()).includes(formattedKeywordsSearch),
    );
  }, [
    search,
    keywordsSearch,
    filteredKeywords,
    duplicatedKeywords,
    isDuplicatedKeywordsShown,
  ]);

  const isSelectAllDisabled = useMemo<boolean>(
    () => !searchedKeywords.length,
    [searchedKeywords.length],
  );

  const { groupedKeywords, groupedDuplicates } = useMemo(() => {
    if (!search) return { groupedKeywords: [], groupedDuplicates: [] };

    const groupedData = getGroupedKeywords(searchedKeywords, search);

    for (const [index, keyword] of groupedData.groupedDuplicates.entries())
      keyword.label = t("component.keywords_table.label.duplicate_group", {
        number: index + 1,
      });

    return groupedData;
  }, [search, searchedKeywords, t]);

  const keywordsTableData = useMemo<Search.FormattedKeyword[]>(
    () => (isDuplicatedKeywordsShown ? groupedDuplicates : groupedKeywords),
    [groupedDuplicates, groupedKeywords, isDuplicatedKeywordsShown],
  );

  const hasDuplicates = useMemo<boolean>(
    () => Boolean(groupedDuplicates.length),
    [groupedDuplicates],
  );

  const keywordsSelectStatus = useMemo<SelectStatus>(() => {
    if (!searchedKeywords.length) return "unchecked";

    let [isAllSelected, isPartSelected] = [true, false];

    for (const { string: keyword } of searchedKeywords) {
      const isKeywordSelected = Boolean(selectedKeywords[keyword]);

      if (!isKeywordSelected) {
        isAllSelected = false;

        continue;
      }

      isPartSelected = true;
    }

    switch (true) {
      case isAllSelected:
        return "checked";
      case !isAllSelected && isPartSelected:
        return "partial";
      case !isAllSelected && !isPartSelected:
      default:
        return "unchecked";
    }
  }, [searchedKeywords, selectedKeywords]);

  const isKeywordsSelectionChanged = useMemo<boolean>(() => {
    const [defaultSelectedKeywordsArray, selectedKeywordsArray] = [
      Object.keys(defaultSelectedKeywords).sort(),
      Object.keys(selectedKeywords).sort(),
    ];

    return (
      JSON.stringify(defaultSelectedKeywordsArray) !==
      JSON.stringify(selectedKeywordsArray)
    );
  }, [defaultSelectedKeywords, selectedKeywords]);

  const isUpdateLoading = useMemo<boolean>(
    () => updateLoadingStatus === "loading",
    [updateLoadingStatus],
  );

  const isUpdateKeywordsDisabled = useMemo<boolean>(
    () =>
      isUpdateLoading ||
      !Object.keys(selectedKeywords).length ||
      !isKeywordsSelectionChanged,
    [isUpdateLoading, selectedKeywords, isKeywordsSelectionChanged],
  );

  const selectKeywordsHandler = useCallback(
    (values: Keyword[]): void => {
      const newSelectedKeywords = { ...selectedKeywords };

      for (const value of values) {
        const isKeywordSelected = selectedKeywords[value] || false;

        if (isKeywordSelected) {
          delete newSelectedKeywords[value];
        } else {
          newSelectedKeywords[value] = true;
        }
      }

      setSelectedKeywords(newSelectedKeywords);
    },
    [selectedKeywords],
  );

  useEffect(() => {
    if (
      isKeywordsPending ||
      isKeywordsLoading ||
      !keywords.length ||
      exactMatchKeywords.length
    )
      return;

    setIsKeywordsExactMatch(false);
  }, [
    keywords.length,
    isKeywordsPending,
    isKeywordsLoading,
    exactMatchKeywords.length,
  ]);

  useEffect(() => {
    const hasKeywordsLoaded =
      previousKeywordsDataStatus === "loading" &&
      keywordsDataStatus === "succeeded";

    if (hasKeywordsLoaded) setSelectedKeywords(defaultSelectedKeywords);
  }, [defaultSelectedKeywords, keywordsDataStatus, previousKeywordsDataStatus]);

  const selectAllKeywordsHandler = (): void => {
    if (keywordsSelectStatus === "checked")
      return selectKeywordsHandler(
        searchedKeywords.map(({ string }) => string),
      );

    const unselectedKeywords = new Set<Keyword>();

    for (const { string: keyword } of searchedKeywords) {
      const isKeywordSelected = Boolean(selectedKeywords[keyword]);

      if (!isKeywordSelected) unselectedKeywords.add(keyword);
    }

    return selectKeywordsHandler([...unselectedKeywords]);
  };

  const selectRecommendedKeywordsHandler = async (): Promise<void> => {
    if (!search) return;

    try {
      setKeywordsSelectLoadingStatus("loading");

      const keywords = await getSearchSelectedKeywords(searchId);

      const selectedKeywords = new Map<Keyword, true>();

      for (const keyword of keywords) selectedKeywords.set(keyword, true);

      setSelectedKeywords(Object.fromEntries(selectedKeywords));

      setAutoSelectedKeywords(Object.fromEntries(selectedKeywords));

      setKeywordsSelectLoadingStatus("succeeded");
    } catch (error) {
      console.error(error);

      setKeywordsSelectLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  const saveSelectedKeywordsHandler = async (): Promise<void> => {
    if (isUpdateKeywordsDisabled) return;

    try {
      setUpdateLoadingStatus("loading");

      await dispatch(
        updateSearchConfiguration({
          id: searchId,
          changes: { keywords: Object.keys(selectedKeywords) },
        }),
      ).unwrap();

      // Trigger update of trackers collections to trigger widgets' data update
      const trackersCollectionsPayload = trackersCollections.map(({ id }) => ({
        id,
        changes: {},
      }));

      // Trigger update of trackers
      const trackersPayload = trackers.map(({ id }) => ({ id, changes: {} }));

      // Trigger update of search
      const searchPayload: Store.UpdateEntity<Search.Data> = {
        id: searchId,
        changes: { status: "READY" },
      };

      await Promise.all([
        dispatch(updateSearch(searchPayload)),
        dispatch(updateTrackers(trackersPayload)),
        dispatch(
          updateTrackersCollections(trackersCollectionsPayload),
        ).unwrap(),
      ]);

      showToastNotification({
        type: "success",
        text: t("component.keywords_table.status.success.keywords_updated"),
      });

      setUpdateLoadingStatus("succeeded");

      setIsOpened(false);
    } catch (error) {
      console.error(error);

      setUpdateLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  const onSidebarClose = (): void => setIsOpened(false);

  return (
    <Sidebar isSidebarOpen={isOpened} setIsSidebarOpen={setIsOpened}>
      <div className={styles.wrapper}>
        <div className={styles.keywordsInfo}>
          <KeywordsInfo
            searchId={searchId}
            keywords={keywords}
            filteredKeywords={filteredKeywords}
            selectedKeywords={selectedKeywords}
            isKeywordsLoading={isKeywordsLoading}
            isKeywordsPending={isKeywordsPending}
            duplicatedKeywords={duplicatedKeywords}
          />
        </div>
        <div className={styles.keywordsTable}>
          <KeywordsControl
            hasDuplicates={hasDuplicates}
            keywordsSearch={keywordsSearch}
            selectedKeywords={selectedKeywords}
            isKeywordsLoading={isKeywordsLoading}
            setKeywordsSearch={setKeywordsSearch}
            isKeywordsPending={isKeywordsPending}
            autoSelectedKeywords={autoSelectedKeywords}
            isKeywordsExactMatch={isKeywordsExactMatch}
            setIsKeywordsExactMatch={setIsKeywordsExactMatch}
            isDuplicatedKeywordsShown={isDuplicatedKeywordsShown}
            keywordsSelectLoadingStatus={keywordsSelectLoadingStatus}
            setIsDuplicatedKeywordsShown={setIsDuplicatedKeywordsShown}
            selectRecommendedKeywordsHandler={selectRecommendedKeywordsHandler}
          />
          <KeywordsTable
            searchId={searchId}
            data={keywordsTableData}
            selectedKeywords={selectedKeywords}
            isKeywordsLoading={isKeywordsLoading}
            isKeywordsPending={isKeywordsPending}
            isSelectAllDisabled={isSelectAllDisabled}
            keywordsSelectStatus={keywordsSelectStatus}
            isDuplicatedKeywordsShown={isDuplicatedKeywordsShown}
            selectAllKeywordsHandler={selectAllKeywordsHandler}
            selectKeywordsHandler={selectKeywordsHandler}
          />
          <div className={styles.buttonsWrapper}>
            <Button
              className={styles.button}
              buttonStyle="outlined"
              onClick={onSidebarClose}
            >
              {t("component.keywords_table.button.cancel")}
            </Button>
            <Button
              className={styles.button}
              onClick={saveSelectedKeywordsHandler}
              disabled={isUpdateKeywordsDisabled}
            >
              {isUpdateLoading ? (
                <Loader className={styles.loader} />
              ) : (
                t("component.keywords_table.button.submit")
              )}
            </Button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};
