const AUTH_ROUTES = {
  login: "/login",
  signUp: "/signup",
  passwordReset: "/password-reset",
  verifyEmailPage: "/verify/:userId",
  verifyToken: "/verify/:userId/:token",
} as const;

// Dashboard pages
const DASHBOARD_GENERAL_LINK = "/market-brief";
const DASHBOARD_ROUTES = {
  dashboardsHomePage: DASHBOARD_GENERAL_LINK,
  createDashboardPage: `${DASHBOARD_GENERAL_LINK}/new`,
  editDashboardPage: `${DASHBOARD_GENERAL_LINK}/edit/:dashboardId`,
  dashboardPage: `${DASHBOARD_GENERAL_LINK}/:dashboardId`,
  dashboardsSearch: `${DASHBOARD_GENERAL_LINK}/search`,
} as const;

// Trackers pages
const TRACKERS_GENERAL_LINK = "/topic";
const TRACKERS_ROUTES = {
  trackersHomePage: TRACKERS_GENERAL_LINK,
  createTracker: `${TRACKERS_GENERAL_LINK}/new`,
  editTracker: `${TRACKERS_GENERAL_LINK}/edit/:trackerId`,
  trackersSearch: `${TRACKERS_GENERAL_LINK}/search`,
} as const;

const SIDEBAR_ROUTES = {
  settingsPage: "/settings",
  settingsProfilePage: "/settings#profile",
  settingsBillingPage: "/settings#billing",
  settingsMembersPage: "/settings#members",
  settingsCompanyPage: "/settings#company",
  trackersPage: TRACKERS_ROUTES.trackersHomePage,
  dashboardsPage: DASHBOARD_ROUTES.dashboardsHomePage,
  logout: "/logout",
} as const;

// Create tracker pages
const CREATE_TRACKER_ROUTES = {
  trackerIdentification: TRACKERS_ROUTES.createTracker,
  trackerSelection: `${TRACKERS_ROUTES.createTracker}/selection`,
  trackerConfiguration: `${TRACKERS_ROUTES.createTracker}/configuration`,
};

// Explore trend pages
const EXPLORE_TREND_LINK = "/explore";
const EXPLORE_TREND_ROUTES = {
  exploreTrend: EXPLORE_TREND_LINK,
  exploreTrendSearch: `${EXPLORE_TREND_LINK}/search`,
  exploreTrendSuggestion: `${EXPLORE_TREND_LINK}/suggestion`,
} as const;

const SHARE_ROUTE = "/share";
const SHARE_DASHBOARD_ROUTE = `${SHARE_ROUTE}/market-brief`;
const SHARE_ROUTES = {
  share: SHARE_ROUTE,
  shareDashboard: SHARE_DASHBOARD_ROUTE,
  shareDashboardPage: `${SHARE_DASHBOARD_ROUTE}/:dashboardId`,
};

const DEFAULT_ROUTE = TRACKERS_ROUTES.createTracker;

export const ROUTES = {
  ...SHARE_ROUTES,
  ...SIDEBAR_ROUTES,
  ...DASHBOARD_ROUTES,
  ...TRACKERS_ROUTES,
  ...AUTH_ROUTES,
  ...CREATE_TRACKER_ROUTES,
  ...EXPLORE_TREND_ROUTES,
  DEFAULT_ROUTE,
} as const;
