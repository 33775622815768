import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { selectDashboardDateRangesByTrackersCollectionId } from "src/store/selectors";
import {
  createDashboardDefaultDateRanges,
  fetchDashboardDateRangesByTrackersCollectionId,
} from "src/store/actions";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const useDashboardDateRangesLoader = ({
  trackersCollectionId,
}: Props) => {
  const dispatch = useAppDispatch();

  const dashboardDateRanges = useSelector((state: Store.RootState) =>
    selectDashboardDateRangesByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoaded = useMemo<boolean>(
    () => Boolean(dashboardDateRanges.length),
    [dashboardDateRanges.length],
  );

  useEffect(() => {
    if (isLoaded) setLoadingStatus("succeeded");
    else setLoadingStatus("idle");
  }, [isLoaded]);

  useEffect(() => {
    if (loadingStatus !== "idle" || dashboardDateRanges.length) return;

    setLoadingStatus("loading");

    dispatch(
      fetchDashboardDateRangesByTrackersCollectionId(trackersCollectionId),
    )
      .unwrap()
      .then((dashboardDateRanges) => {
        if (dashboardDateRanges.length) setLoadingStatus("succeeded");
        else
          dispatch(createDashboardDefaultDateRanges({ trackersCollectionId }))
            .unwrap()
            .then(() => setLoadingStatus("succeeded"));
      })
      .catch((error) => {
        console.error(error);

        setLoadingStatus("failed");
      });
  }, [dispatch, loadingStatus, trackersCollectionId, dashboardDateRanges]);

  return { loadingStatus };
};
