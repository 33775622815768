import firestore from "src/services/firestore";
import { showDevelopmentError } from "src/utils";

import { getTimestamps } from "../utils";
import { COLLECTION_IDS } from "../constants";

// Inner imports
import * as schemas from "./dashboardDateRangesSchema";

export const getDashboardDateRangesByCompanyId = async (
  companyId: Company.Data["id"],
): Promise<DashboardDateRange.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.dashboardDateRanges)
    .where("companyId", "==", companyId)
    .get();

  const result = new Set<DashboardDateRange.Data>();

  for (const doc of collection.docs) {
    try {
      const dashboardDateRange = schemas.dashboardDateRangeSchema.validateSync(
        doc.data(),
      );

      const formattedDashboardDateRange =
        formatDashboardDateRange(dashboardDateRange);

      result.add({
        ...formattedDashboardDateRange,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "DASHBOARD DATE RANGE VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return [...result];
};

export const getDashboardDateRangesByTrackersCollectionId = async (
  trackersCollectionId: TrackersCollection.Data["id"],
  companyId: Company.Data["id"],
): Promise<DashboardDateRange.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.dashboardDateRanges)
    .where("companyId", "==", companyId)
    .where("trackersCollectionId", "==", trackersCollectionId)
    .get();

  const result = new Set<DashboardDateRange.Data>();

  for (const doc of collection.docs) {
    try {
      const dashboardDateRange = schemas.dashboardDateRangeSchema.validateSync(
        doc.data(),
      );

      const formattedDashboardDateRange =
        formatDashboardDateRange(dashboardDateRange);

      result.add({
        ...formattedDashboardDateRange,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "DASHBOARD DATE RANGE VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return [...result];
};

export const getDashboardDateRangesByIds = async (
  dashboardDateRangesIds: DashboardDateRange.Data["id"][],
): Promise<DashboardDateRange.Data[]> => {
  const promises = new Set<
    Promise<firestore.DocumentSnapshot<firestore.DocumentData>>
  >();

  for (const dashboardDateRangeId of dashboardDateRangesIds) {
    const docRef = firestore()
      .collection(COLLECTION_IDS.dashboardDateRanges)
      .doc(dashboardDateRangeId)
      .get();

    promises.add(docRef);
  }

  const result = await Promise.all(promises);

  const dashboardDateRanges = new Set<DashboardDateRange.Data>();

  for (const doc of result) {
    try {
      const dashboardDateRange = schemas.dashboardDateRangeSchema.validateSync(
        doc.data(),
      );

      const formattedDashboardDateRange =
        formatDashboardDateRange(dashboardDateRange);

      dashboardDateRanges.add({ ...formattedDashboardDateRange, id: doc.id });
    } catch (error) {
      const errorTitle = "DASHBOARD DATE RANGE VALIDATION ERROR";

      showDevelopmentError({ additionalTexts: [errorTitle], error });
    }
  }

  return [...dashboardDateRanges];
};

export const createDashboardDateRange = async (
  payload: Store.CreateEntity<DashboardDateRange.Data>,
): Promise<DashboardDateRange.Data> => {
  const { createdAt, updatedAt } = getTimestamps();

  const _payload = {
    ...payload,
    createdAt,
    updatedAt,
  };

  const { startDate, endDate, ...rest } = _payload;

  const formattedPayload = { ...rest, dateFrom: startDate, dateTo: endDate };

  const doc = await firestore()
    .collection(COLLECTION_IDS.dashboardDateRanges)
    .add(formattedPayload);

  return { ..._payload, id: doc.id };
};

export const createDashboardDateRanges = async (
  payload: Store.CreateEntity<DashboardDateRange.Data>[],
): Promise<DashboardDateRange.Data[]> => {
  const { createdAt, updatedAt } = getTimestamps();

  const batch = firestore().batch();

  const formattedDashboardDateRanges = new Set<DashboardDateRange.Data>();

  for (const dashboardDateRange of payload) {
    const _dashboardDateRange = { ...dashboardDateRange, createdAt, updatedAt };

    const { startDate, endDate, ...rest } = _dashboardDateRange;

    const formattedDashboardDateRange = {
      ...rest,
      dateFrom: startDate,
      dateTo: endDate,
    };

    const docRef = firestore()
      .collection(COLLECTION_IDS.dashboardDateRanges)
      .doc();

    batch.set(docRef, formattedDashboardDateRange);

    formattedDashboardDateRanges.add({ ..._dashboardDateRange, id: docRef.id });
  }

  await batch.commit();

  return [...formattedDashboardDateRanges];
};

export const updateDashboardDateRangesByAuthorId = async (
  {
    changes,
    authorId,
  }: {
    changes: Store.UpdateEntity<DashboardDateRange.Data>["changes"];
    authorId: DashboardDateRange.Data["authorId"];
  },
  companyId: Company.Data["id"],
): Promise<Store.UpdateEntity<DashboardDateRange.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<DashboardDateRange.Data>[] = [];

  const batch = firestore().batch();

  const dashboardDateRangesByAuthorId = await firestore()
    .collection(COLLECTION_IDS.dashboardDateRanges)
    .where("companyId", "==", companyId)
    .where("authorId", "==", authorId)
    .get();

  for (const { id, ref } of dashboardDateRangesByAuthorId.docs) {
    const _changes = { ...changes, updatedAt };

    batch.set(ref, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });
  }

  await batch.commit();

  return updatedPayload;
};

export const deleteDashboardDateRange = async (
  id: DashboardDateRange.Data["id"],
): Promise<DashboardDateRange.Data["id"]> => {
  await firestore()
    .collection(COLLECTION_IDS.dashboardDateRanges)
    .doc(id)
    .delete();

  return id;
};

function formatDashboardDateRange({
  type = null,
  dateTo,
  dateFrom,
  ...rest
}: schemas.DashboardDateRangeSchemaType): Omit<DashboardDateRange.Data, "id"> {
  return {
    ...rest,
    type,
    endDate: dateTo,
    startDate: dateFrom,
  };
}
