import { createDraftSafeSelector } from "@reduxjs/toolkit";

// Inner imports
import { dashboardDateRangesAdapter } from "./dashboardDateRangesSlice";

export const {
  selectAll: selectDashboardDateRanges,
  selectEntities: selectDashboardDateRangesEntities,
  selectTotal: selectDashboardDateRangesCount,
  selectById: selectDashboardDateRangeById,
} = dashboardDateRangesAdapter.getSelectors<Store.RootState>(
  ({ dashboardDateRanges }) => dashboardDateRanges,
);

export const selectDashboardDateRangesByTrackersCollectionId =
  createDraftSafeSelector(
    (
      state: Store.RootState,
      trackersCollectionId: TrackersCollection.Data["id"],
    ): [
      ReturnType<typeof selectDashboardDateRanges>,
      TrackersCollection.Data["id"],
    ] => [selectDashboardDateRanges(state), trackersCollectionId],
    ([dashboardDateRanges, trackersCollectionId]) => {
      const filteredDashboardDateRanges = new Set<DashboardDateRange.Data>();

      for (const dashboardDateRange of dashboardDateRanges) {
        const isTrackersCollectionDashboardDateRange =
          dashboardDateRange.trackersCollectionId === trackersCollectionId;

        if (isTrackersCollectionDashboardDateRange)
          filteredDashboardDateRanges.add(dashboardDateRange);
      }

      return [...filteredDashboardDateRanges];
    },
  );

export const selectDefaultDashboardDateRangeByTrackersCollectionId =
  createDraftSafeSelector(
    (
      state: Store.RootState,
      trackersCollectionId: TrackersCollection.Data["id"],
    ): [ReturnType<typeof selectDashboardDateRangesByTrackersCollectionId>] => [
      selectDashboardDateRangesByTrackersCollectionId(
        state,
        trackersCollectionId,
      ),
    ],
    ([dashboardDateRanges]) => {
      const dateRangeTypeAll = dashboardDateRanges.find(
        ({ type }) => type === "all",
      );

      return dateRangeTypeAll || dashboardDateRanges[0];
    },
  );
