import { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import styles from "./DashboardDateRangeActions.module.scss";
import { useModal } from "src/hooks";
import { Button, Tooltip } from "src/components";
import { TrashOutline } from "src/assets/icons";
import { ConfirmDeleteDashboardDateRangeModal } from "src/features";

type Props = {
  dashboardDateRange: DashboardDateRange.Data;
};

export const DashboardDateRangeActions: FC<Props> = ({
  dashboardDateRange,
}) => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const onDelete = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();

    setModal(
      <ConfirmDeleteDashboardDateRangeModal
        dashboardDateRangeId={dashboardDateRange.id}
      />,
    );
  };

  return (
    <Tooltip content={t("page.dashboard.tooltip.delete_dashboard_date_range")}>
      <Button
        buttonSize="small"
        onClick={onDelete}
        buttonStyle="transparent"
        className={styles.button}
      >
        <TrashOutline />
      </Button>
    </Tooltip>
  );
};
