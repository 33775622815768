import { FC, useMemo, useRef } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

import styles from "./WidgetTile.module.scss";
import { useCurrentTime, useModal } from "src/hooks";
import { Lock, Loader, Clock } from "src/assets/icons";
import { ExternalTooltip, Preloader, Tooltip } from "src/components";
import {
  WIDGET_IDS_WITH_FORECAST,
  WIDGET_TITLE_EXTERNAL_TOOLTIP,
} from "src/constants";
import {
  PreReleaseComponent,
  UpgradeSubscriptionPlanModal,
} from "src/features";
import {
  getIconByName,
  compareTwoStrings,
  composeWidgetSyncId,
} from "src/utils";
import {
  selectWidgetStatus,
  selectWidgetInfoById,
  selectWidgetSyncById,
  selectTrackersCollectionById,
  selectDashboardWidgetSettings,
} from "src/store/selectors";
import { useWidgetPreview } from "../Widgets/hooks";

// Inner imports
import { WIDGETS_COMPONENTS } from "../Widgets/constants";
import {
  useIsWidgetLocked,
  useWidgetCalculation,
  useIsWidgetForecastCalculated,
} from "./hooks";
import {
  WIDGET_SYNC_MAX_DELAY,
  WIDGET_SYNC_CURRENT_TIME_DELAY,
} from "./constants";
import {
  PendingStub,
  WidgetSource,
  WidgetFooter,
  NoWidgetDataStub,
  WidgetTileButtons,
  CompetitorRequiredStub,
} from "./components";

type Props = {
  widgetId: Widget.IdType;
  isReadOnly: boolean;
  trackerIds: Tracker.Data["id"][];
  expandedWidgetId: Widget.IdType | null;
  setExpandedWidgetId: (value: Widget.IdType | null) => void;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const WidgetTile: FC<Props> = ({
  widgetId,
  isReadOnly,
  trackerIds,
  expandedWidgetId,
  setExpandedWidgetId,
  trackersCollectionId,
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const widgetTileRef = useRef<HTMLDivElement>(null);

  const widgetInfo = useSelector((state: Store.RootState) =>
    selectWidgetInfoById(state, widgetId),
  );

  const dashboardWidgetSettings = useSelector((state: Store.RootState) =>
    selectDashboardWidgetSettings(state, trackersCollectionId, widgetId),
  );

  const widgetSync = useSelector((state: Store.RootState) =>
    selectWidgetSyncById(
      state,
      composeWidgetSyncId({
        widgetId,
        trackersCollectionId,
        dashboardDateRangeId,
      }),
    ),
  );

  const widgetStatus = useSelector((state: Store.RootState) =>
    selectWidgetStatus(state, dashboardDateRangeId, widgetId),
  );

  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, trackersCollectionId),
  );

  const isWidgetLocked = useIsWidgetLocked(
    trackersCollectionId,
    widgetId,
    isReadOnly,
  );

  useWidgetCalculation({
    widgetId,
    isReadOnly,
    trackersCollectionId,
    dashboardDateRangeId,
  });

  const currentTime = useCurrentTime(WIDGET_SYNC_CURRENT_TIME_DELAY);

  const isExpanded = useMemo<boolean>(() => {
    if (!expandedWidgetId) return false;

    return compareTwoStrings(expandedWidgetId, widgetId);
  }, [expandedWidgetId, widgetId]);

  const widgetChartProps = useMemo<Widget.ChartProps>(
    () => ({
      widgetId,
      isExpanded,
      trackerIds,
      trackersCollectionId,
      dashboardDateRangeId,
    }),
    [
      widgetId,
      trackerIds,
      isExpanded,
      trackersCollectionId,
      dashboardDateRangeId,
    ],
  );

  const {
    setViewIndex,
    viewIndex,
    widgetViews: views,
  } = useWidgetPreview(widgetChartProps);

  const isForecastCalculated = useIsWidgetForecastCalculated(widgetChartProps);

  const hasCompetitors = useMemo<boolean>(() => {
    if (!trackersCollection?.trackerIds) return false;

    return trackersCollection.trackerIds.length > 1;
  }, [trackersCollection?.trackerIds]);

  const widgetViewMetadata = useMemo<WidgetsInfo.View | undefined>(() => {
    const view = views[viewIndex];

    if (!view) return;

    return widgetInfo?.views.find(({ type }) => type === view.type);
  }, [viewIndex, views, widgetInfo?.views]);

  const widgetPreviewProps = useMemo<Widget.PreviewProps>(
    () => ({
      ...widgetChartProps,
      view: views[viewIndex],
      views,
    }),
    [viewIndex, views, widgetChartProps],
  );

  const WidgetPreviewComponent = useMemo<JSX.Element>(() => {
    const Preview = WIDGETS_COMPONENTS[widgetId].previewComponent;

    return <Preview {...widgetPreviewProps} />;
  }, [widgetId, widgetPreviewProps]);

  const Widget = useMemo<JSX.Element>(() => {
    if (widgetStatus === "idle")
      return <Preloader className={styles.preloader} type="bar" />;

    if (
      widgetStatus === "loading" ||
      widgetStatus === "not_calculated" ||
      widgetStatus === "sync_triggered"
    )
      return <Preloader className={styles.preloader} type="bar" />;

    if (widgetStatus === "calculating") return <PendingStub />;

    if (
      !hasCompetitors &&
      widgetViewMetadata &&
      !widgetViewMetadata.isAvailableForOneTracker
    )
      return (
        <CompetitorRequiredStub
          widgetId={widgetId}
          dashboardId={trackersCollectionId}
        />
      );

    switch (widgetStatus) {
      case "fetch_failed":
      case "schema_failed":
      case "data_empty":
        return <NoWidgetDataStub />;
      default:
        return WidgetPreviewComponent;
    }
  }, [
    widgetId,
    widgetStatus,
    hasCompetitors,
    widgetViewMetadata,
    trackersCollectionId,
    WidgetPreviewComponent,
  ]);

  const WidgetIcon = useMemo<JSX.Element | null>(() => {
    const iconName = WIDGETS_COMPONENTS[widgetId].icon;

    return getIconByName(iconName, styles.widgetIcon);
  }, [widgetId]);

  const widgetName = useMemo<string>(
    () => t(dashboardWidgetSettings?.name || widgetInfo?.placeHolderOnUi || ""),
    [t, dashboardWidgetSettings?.name, widgetInfo?.placeHolderOnUi],
  );

  const isUpdateIconShown = useMemo<boolean>(
    () => widgetStatus === "updating",
    [widgetStatus],
  );

  const isWidgetSyncDelayIconShown = useMemo<boolean>(() => {
    if (!widgetSync?.startedAt) return false;

    if (widgetStatus !== "calculating" && widgetStatus !== "updating")
      return false;

    const widgetSyncCalculationTimeInMs = Math.abs(
      differenceInMilliseconds(currentTime, new Date(widgetSync.startedAt)),
    );

    return widgetSyncCalculationTimeInMs >= WIDGET_SYNC_MAX_DELAY;
  }, [currentTime, widgetStatus, widgetSync?.startedAt]);

  const isWidgetLockShown = useMemo<boolean>(
    () => isWidgetLocked,
    [isWidgetLocked],
  );

  const updateIconTooltip = useMemo<string>(() => {
    if (!WIDGET_IDS_WITH_FORECAST.includes(widgetId)) return "";

    return isForecastCalculated
      ? ""
      : t("component.widget_tile.tooltip.forecast_calculation");
  }, [isForecastCalculated, t, widgetId]);

  const onOpenInformModalHandler = (): void => {
    if (isReadOnly) return;

    setModal(
      <UpgradeSubscriptionPlanModal content="component.widget_tile.status.info.upgrade_subscription_plan" />,
    );
  };

  return (
    <div className={styles.wrapper} ref={widgetTileRef}>
      <div className={styles.header}>
        <div className={styles.widgetInfo}>
          {WidgetIcon}
          <div className={styles.widgetName}>{widgetName}</div>
          <PreReleaseComponent widgetId={widgetId} />
          <ExternalTooltip
            tooltipId={WIDGET_TITLE_EXTERNAL_TOOLTIP[widgetId]}
          />
          <div className={styles.widgetIcons}>
            {isUpdateIconShown && (
              <Tooltip content={updateIconTooltip}>
                <Loader className={styles.loader} />
              </Tooltip>
            )}
            {isWidgetSyncDelayIconShown && (
              <Tooltip
                content={t("component.widget_tile.status.info.sync_delay")}
              >
                <Clock />
              </Tooltip>
            )}
          </div>
        </div>
        <WidgetTileButtons
          views={views}
          ref={widgetTileRef}
          viewIndex={viewIndex}
          isReadOnly={isReadOnly}
          setViewIndex={setViewIndex}
          chartProps={widgetChartProps}
          setExpandedWidgetId={setExpandedWidgetId}
          dashboardDateRangeId={dashboardDateRangeId}
        />
      </div>
      <div
        className={cx(styles.content, isWidgetLockShown ? styles.locked : "")}
      >
        {Widget}
      </div>
      {isWidgetLockShown && (
        <div className={styles.lock} onClick={onOpenInformModalHandler}>
          <Lock />
        </div>
      )}
      {isExpanded && <WidgetSource widgetId={widgetId} />}
      <WidgetFooter {...widgetChartProps} />
    </div>
  );
};
