import { FC, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { useSelector } from "react-redux";
import isSameDay from "date-fns/isSameDay";
import { useTranslation } from "react-i18next";

import styles from "./CreateEventModal.module.scss";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { createEvent } from "src/store/actions";
import { useElementFocus, useModal, useTemporaryErrors } from "src/hooks";
import {
  selectEventsLimit,
  selectEventsByDashboardId,
} from "src/store/selectors";
import {
  EVENT_DEFAULT_TYPE,
  EVENT_INPUT_LIMIT,
  EVENT_TYPE_OPTIONS,
} from "src/constants";
import {
  Form,
  Input,
  Label,
  Select,
  Translation,
  DateSelector,
} from "src/components";
import {
  removeExtraSpaces,
  formatToYearMonthDay,
  showToastNotification,
  isEventTypeTypeGuard,
  getDifferenceInObjects,
} from "src/utils";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

const InputWithError = withError(Input);

const DateSelectorWithError = withError(DateSelector);

type Props = {
  dashboardId: Dashboard.Data["id"];
  endDate?: DashboardDateRange.Data["endDate"];
  startDate?: DashboardDateRange.Data["startDate"];
};

export const CreateEventModal: FC<Props> = ({
  dashboardId,
  endDate: defaultEndDate,
  startDate: defaultStartDate,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const dispatch = useAppDispatch();

  const [ref, setFocus] = useElementFocus();

  const defaultEventType = useMemo<Event.Type>(() => {
    if (
      defaultStartDate &&
      defaultEndDate &&
      defaultEndDate !== defaultStartDate
    )
      return "range";

    return EVENT_DEFAULT_TYPE;
  }, [defaultEndDate, defaultStartDate]);

  const defaultEvent = useMemo<Omit<Event.Data, "id">>(() => {
    let startDate: DashboardDateRange.Data["startDate"];
    let endDate: DashboardDateRange.Data["endDate"];

    if (defaultStartDate && defaultEndDate) {
      startDate = formatToYearMonthDay(defaultStartDate);
      endDate = formatToYearMonthDay(defaultEndDate);
    } else {
      const defaultDate = formatToYearMonthDay(new Date());

      startDate = defaultDate;
      endDate = defaultDate;
    }

    return {
      name: "",
      endDate,
      startDate,
      dashboardId,
      authorId: "",
      createdAt: "",
      updatedAt: "",
      companyId: "",
      isActive: true,
      description: "",
    };
  }, [dashboardId, defaultEndDate, defaultStartDate]);

  const events = useSelector((state: Store.RootState) =>
    selectEventsByDashboardId(state, dashboardId),
  );

  const { isEventsLimitExceeded, eventsLimit } = useSelector(selectEventsLimit);

  const [event, setEvent] = useImmer<Omit<Event.Data, "id">>(defaultEvent);

  const [eventType, setEventType] = useState<Event.Type>(defaultEventType);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const { errors, setErrors } = useTemporaryErrors(3000);

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isSingleDateSelected = useMemo<boolean>(
    () => eventType === "single",
    [eventType],
  );

  const isEventChanged = useMemo<boolean>(
    () => Boolean(getDifferenceInObjects(event, defaultEvent)),
    [event, defaultEvent],
  );

  const isDisabled = useMemo<boolean>(
    () => isLoading || !isEventChanged,
    [isLoading, isEventChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onNameChange = (value: string): void =>
    setEvent((draft) => {
      draft.name = value;
    });

  const onDescriptionChange = (value: string): void =>
    setEvent((draft) => {
      draft.description = value;
    });

  const onTypeChange = (value: string): void => {
    if (!isEventTypeTypeGuard(value)) return;

    setEventType(value);
  };

  const onDateChange = (value: string): void =>
    setEvent((draft) => {
      draft.startDate = value;
      draft.endDate = value;
    });

  const onStartDateChange = (value: string): void => {
    setEvent((draft) => {
      draft.startDate = value;
    });

    const [formattedStartDate, formattedEndDate] = [
      new Date(value),
      new Date(event.endDate),
    ];

    if (!isBefore(formattedStartDate, formattedEndDate))
      setEvent((draft) => {
        draft.endDate = value;
      });
  };

  const onEndDateChange = (value: string): void =>
    setEvent((draft) => {
      draft.endDate = value;
    });

  const onSubmit = async (): Promise<void> => {
    if (isEventsLimitExceeded)
      return showToastNotification({
        id: "event-limit",
        type: "warning",
        text: (
          <Translation
            i18nKey="event.status.warning.event_limit"
            values={{ count: eventsLimit }}
          />
        ),
      });

    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    try {
      setLoadingStatus("loading");

      await dispatch(
        createEvent({
          ...event,
          name: removeExtraSpaces(event.name),
          description: removeExtraSpaces(event.description),
        }),
      ).unwrap();

      setLoadingStatus("succeeded");

      showToastNotification({
        id: "create_event_success",
        type: "success",
        text: t(
          "component.modal.create_event.form.status.success.event_created",
        ),
      });

      closeModal();
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      setLoadingStatus("failed");
    }
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const { name, startDate, endDate } = event;

    if (name.trim().length) {
      const isExistingEventName = events.some((event) => event.name === name);

      if (isExistingEventName)
        validationErrors.name = t(
          "component.modal.create_event.form.validation.name_exists",
        );
    } else {
      validationErrors.name = t(
        "component.modal.create_event.form.validation.name_required",
      );
    }

    const [formattedStartDate, formattedEndDate] = [
      new Date(startDate),
      new Date(endDate),
    ];

    const isExistingDateRange = events.some(
      (event) =>
        isSameDay(new Date(event.startDate), formattedStartDate) &&
        isSameDay(new Date(event.endDate), formattedEndDate),
    );

    if (isExistingDateRange) {
      validationErrors.startDate = t(
        "component.modal.create_event.form.validation.date_exists",
      );
      validationErrors.date = t(
        "component.modal.create_event.form.validation.date_exists",
      );
    }

    if (isAfter(formattedStartDate, formattedEndDate))
      validationErrors.startDate = t(
        "component.modal.create_event.form.validation.incorrect_date",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      type="success"
      acceptButton={{
        onClick: onSubmit,
        text: t("component.modal.create_event.button.submit"),
        disabled: isDisabled,
      }}
      cancelButton={{
        onClick: closeModal,
        text: t("component.modal.create_event.button.cancel"),
      }}
      title={t("component.modal.create_event.title")}
      isLoading={isLoading}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <div className={styles.inputWrapper}>
          <Label leftText={t("component.modal.create_event.form.label.name")} />
          <InputWithError
            ref={ref}
            value={event.name}
            error={errors.name}
            changeHandler={onNameChange}
            characterLimit={EVENT_INPUT_LIMIT}
            placeholder={t(
              "component.modal.create_event.form.placeholder.name",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.create_event.form.label.description")}
          />
          <InputWithError
            value={event.description}
            error={errors.description}
            characterLimit={EVENT_INPUT_LIMIT}
            changeHandler={onDescriptionChange}
            placeholder={t(
              "component.modal.create_event.form.placeholder.description",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.create_event.form.label.event_type")}
          />
          <Select
            value={eventType}
            options={EVENT_TYPE_OPTIONS}
            openingDirection="bottom-end"
            dropdownClassName={styles.selectDropdownWrapper}
            placeholder={t(
              "component.modal.create_event.form.placeholder.event_type",
            )}
            changeHandler={onTypeChange}
          />
        </div>
        {isSingleDateSelected && (
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("component.modal.create_event.form.label.date")}
            />
            <DateSelectorWithError
              value={event.startDate}
              placeholder={t(
                "component.modal.create_event.form.placeholder.date",
              )}
              onChange={onDateChange}
              error={errors.date}
            />
          </div>
        )}
        {!isSingleDateSelected && (
          <div className={styles.groupWrapper}>
            <div className={styles.inputWrapper}>
              <Label
                leftText={t(
                  "component.modal.create_event.form.label.start_date",
                )}
              />
              <DateSelectorWithError
                value={event.startDate}
                placeholder={t(
                  "component.modal.create_event.form.placeholder.start_date",
                )}
                onChange={onStartDateChange}
                error={errors.startDate}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Label
                leftText={t("component.modal.create_event.form.label.end_date")}
              />
              <DateSelectorWithError
                value={event.endDate}
                minValue={event.startDate}
                placeholder={t(
                  "component.modal.create_event.form.placeholder.endDate",
                )}
                onChange={onEndDateChange}
                error={errors.endDate}
              />
            </div>
          </div>
        )}
      </Form>
    </ConfirmModal>
  );
};
