const STRING_FILTER_ACCURACY: number = 3;

export const limitString = (
  value: string,
  options: { wordLimit?: number; characterLimit?: number },
): string => {
  const { characterLimit, wordLimit } = options;

  const valueWords = value.split(" ").filter(Boolean);

  const [isCharacterLimitReached, isWordLimitReached] = [
    characterLimit ? value.length >= characterLimit : false,
    wordLimit ? valueWords.length >= wordLimit : false,
  ];

  let formattedValue = value;

  if (isCharacterLimitReached)
    formattedValue = formattedValue.slice(0, characterLimit);

  if (isWordLimitReached)
    formattedValue = formattedValue
      .split(" ")
      .filter(Boolean)
      .slice(0, wordLimit)
      .join(" ");

  return formattedValue;
};

export const stringFilter = (value: string, filter: string) => {
  const filterSubstring = filter
    .toLowerCase()
    .substring(0, STRING_FILTER_ACCURACY);

  const valueSubstring =
    value.substring(0, STRING_FILTER_ACCURACY).toLowerCase() || "";

  return (
    value.toLowerCase().includes(filterSubstring) ||
    deepStringCheck(valueSubstring, filterSubstring)
  );
};

export const removeLineBreaks = (value: string): string =>
  value.replace(/(\r\n|\n|\r)/gm, "");

function deepStringCheck(
  value: string,
  filter: string,
): RegExpMatchArray | null {
  const pattern = filter
    .split("")
    .map((x) => `/[.*+?=^${escapeHyphen(x)}()|[\]\\]/g`)
    .join("");

  const regex = new RegExp(`${pattern}`, "gi");

  return value.match(regex);
}

function escapeHyphen(value: string): string {
  return value.replace("-", "\\-");
}
