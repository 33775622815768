import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import uniq from "lodash/uniq";

import { getSearchDescription } from "src/store/searches/searchesApi";
import { showDevelopmentError, showToastNotification } from "src/utils";

export const useGetSearchDescription = (
  search: Search.Data | Search.CreationData,
  location?: Location.Data,
) => {
  const { t } = useTranslation();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const defaultExcludedDescriptions = useMemo<Search.Data["description"][]>(
    () => [search.description].filter(Boolean),
    [search.description],
  );

  const [excludedDescriptions, setExcludedDescriptions] = useState<
    Search.Data["description"][]
  >(defaultExcludedDescriptions);

  const _getSearchDescription = async (
    callback?: (description: Search.Data["description"]) => void,
  ): Promise<Search.Data["description"]> => {
    if (!location) return "";

    try {
      setLoadingStatus("loading");

      const description = await getSearchDescription(
        search,
        location,
        excludedDescriptions,
      );

      callback?.(description);

      setLoadingStatus("succeeded");

      setExcludedDescriptions((state) =>
        uniq([...state, description].filter(Boolean)),
      );

      return description;
    } catch (error) {
      showDevelopmentError({
        error,
      });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      callback?.("");

      setLoadingStatus("failed");

      return "";
    }
  };

  return {
    loadingStatus,
    getSearchDescription: _getSearchDescription,
  };
};
