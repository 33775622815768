import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isNumber } from "src/utils";
import { WIDGET_CHART_ICON } from "src/constants";
import { selectIsTrackersCollectionSingleBranded } from "src/store/selectors";

// Inner imports
import { WIDGET_CHART_HOOKS } from "./constants";
import type {
  UseWidgetView,
  WidgetViewsType,
  UseWidgetViewData,
  UseWidgetViewResult,
  UseWidgetPreviewResult,
  WidgetButtonsBarOptions,
  UseWidgetViewDataResult,
  UseWidgetViewIndexResult,
} from "./types";

export const useWidgetView = ({
  charts,
  trackersCollectionId = "",
  widgetId,
}: UseWidgetView): UseWidgetViewResult => {
  const { t } = useTranslation();

  const { widgetAvailableViews, allWidgetViews, filteredWidgetViews } =
    useWidgetViewData({
      charts,
      trackersCollectionId,
      widgetId,
    });

  const [currentWidgetViewId, setCurrentWidgetViewId] = useState<string>("");

  const selectedView = useMemo<Widget.ChartView | undefined>(() => {
    const widgetView = filteredWidgetViews.find(
      ({ id }) => id === currentWidgetViewId,
    );

    if (!widgetView) return filteredWidgetViews[0];

    return widgetView;
  }, [currentWidgetViewId, filteredWidgetViews]);

  const widgetViewsOptions = useMemo<WidgetButtonsBarOptions>(
    () =>
      allWidgetViews.map((view) => {
        const { id, buttonIcon } = view;

        const disableStatus = getDisabledStatus({
          view,
          availableCharts: widgetAvailableViews,
        });

        const disabledStatusLabel = t(disableStatus);
        const isDisabled = !!disableStatus;

        return {
          id,
          icon: buttonIcon,
          handleClick: (widgetViewId) => {
            setCurrentWidgetViewId(widgetViewId);
          },
          isDisabled,
          buttonNumber: getUniqButtonNumbersToView(view, allWidgetViews),
          ...(isDisabled
            ? {
                withTooltip: {
                  customPosition: { vertical: -20, horizontal: -10 },
                  toolTipContent: disabledStatusLabel,
                },
              }
            : {}),
        };
      }),
    [allWidgetViews, widgetAvailableViews, t],
  );

  useEffect(() => {
    const isWidgetViewsInitialized = !!currentWidgetViewId;

    if (isWidgetViewsInitialized) return;

    const firstChartView = filteredWidgetViews[0]?.id || "";
    setCurrentWidgetViewId(firstChartView);
  }, [filteredWidgetViews, currentWidgetViewId]);

  const setCurrentWidgetView = ({ type, subType }: Widget.ChartView) => {
    const widgetViewId = getWidgetChartId(type, subType);

    setCurrentWidgetViewId(widgetViewId);
  };

  return {
    widgetViewsOptions,
    selectedView,
    setCurrentWidgetView,
  };
};

export const useWidgetPreview = (
  widgetChartProps: Widget.ChartProps,
): UseWidgetPreviewResult => {
  const { widgetId, trackersCollectionId } = useMemo<Widget.ChartProps>(
    () => widgetChartProps,
    [widgetChartProps],
  );

  const charts = WIDGET_CHART_HOOKS[widgetId](widgetChartProps);

  const { filteredWidgetViews } = useWidgetViewData({
    charts,
    widgetId,
    trackersCollectionId,
  });

  const [viewIndex, setViewIndex] = useWidgetViewIndex(filteredWidgetViews);

  return {
    viewIndex,
    setViewIndex,
    widgetViews: filteredWidgetViews,
  };
};

function useWidgetViewIndex(
  widgetViews: Widget.ChartView[],
): UseWidgetViewIndexResult {
  const [viewIndex, _setViewIndex] = useState<number>(0);

  const selectedView = useMemo<Widget.ChartView | undefined>(
    () => widgetViews[viewIndex],
    [viewIndex, widgetViews],
  );

  const setViewIndex = (viewIndex: number): void => _setViewIndex(viewIndex);

  useEffect(() => {
    if (!selectedView) return _setViewIndex(0);

    _setViewIndex(viewIndex);
  }, [selectedView, viewIndex]);

  return [viewIndex, setViewIndex];
}

function useWidgetViewData({
  widgetId,
  trackersCollectionId,
  charts,
}: UseWidgetViewData): UseWidgetViewDataResult {
  const widgetAvailableViews = useSelector(
    ({ widgetsInfo }: Store.RootState): WidgetViewsType | undefined => {
      const { views } = widgetsInfo.entities[widgetId] || {};

      return views;
    },
  );

  const isTrackersCollectionSingleBranded = useSelector(
    (state: Store.RootState) =>
      selectIsTrackersCollectionSingleBranded(state, trackersCollectionId),
  );

  const allWidgetViews = useMemo<Widget.ChartView[]>(() => {
    const formattedCharts = charts.map((chart) => ({
      ...chart,
      id: getWidgetChartId(chart.type, chart.subType),
      buttonIcon: getWidgetChartIcon(chart.type, chart.subType),
      order: getChartOrder(chart.type, chart.subType, widgetAvailableViews),
    }));

    return formattedCharts.sort((a, b) => (a?.order || 0) - (b.order || 0));
  }, [charts, widgetAvailableViews]);

  const filteredWidgetViews = useMemo<Widget.ChartView[]>(
    () => filterOutUnavailableViews(allWidgetViews, widgetAvailableViews),
    [allWidgetViews, widgetAvailableViews],
  );

  return {
    widgetAvailableViews,
    isCompetitorsPresent: !isTrackersCollectionSingleBranded,
    allWidgetViews,
    filteredWidgetViews,
  };
}

function getWidgetChartId(
  chartType: Widget.ChartType,
  chartSubtype?: string,
): string {
  if (chartType && chartSubtype) return `${chartType}_${chartSubtype}`;

  if (chartType) return chartType;

  return "";
}

function getWidgetChartIcon(
  chartType: Widget.ChartType,
  chartSubtype: string = "default",
): AppIcon {
  const icon = WIDGET_CHART_ICON[chartType][chartSubtype];

  if (!icon) return WIDGET_CHART_ICON[chartType].default;

  return icon;
}

function getChartOrder(
  chartType: Widget.ChartType,
  chartSubtype: string = "",
  availableCharts?: WidgetViewsType,
): number {
  const { order = 0 } =
    availableCharts?.find(
      ({ type: availableChartType, subType: availableChartSubType = "" }) =>
        chartType === availableChartType &&
        chartSubtype === availableChartSubType,
    ) || {};

  return order;
}

function filterOutUnavailableViews(
  views: Widget.ChartView[],
  availableCharts?: WidgetViewsType,
): Widget.ChartView[] {
  if (!availableCharts) return [];
  const filteredViews: Widget.ChartView[] = [];

  for (const view of views) {
    const { type, subType = "" } = view;

    const widgetView = availableCharts.find(
      ({ type: availableChartType, subType: availableChartSubType = "" }) =>
        type === availableChartType && subType === availableChartSubType,
    );

    if (widgetView)
      filteredViews.push({
        ...view,
        order: widgetView.order,
        buttonNumber: getUniqButtonNumbersToView(view, views),
      });
  }

  return filteredViews;
}

function getUniqButtonNumbersToView(
  view: Widget.ChartView,
  views: Widget.ChartView[],
): number | undefined {
  const { buttonIcon, buttonNumber, id } = view;

  if (isNumber(buttonNumber)) return buttonNumber;

  const viewsWithSameButtonIcon = views.filter(
    (item) => item.buttonIcon === buttonIcon,
  );

  if (viewsWithSameButtonIcon.length < 2) return;

  const newButtonNumber = viewsWithSameButtonIcon.findIndex(
    (item) => item.id === id,
  );

  if (newButtonNumber < 0) return;

  return newButtonNumber + 1;
}

function getDisabledStatus({
  view: { type, subType = "" },
  availableCharts,
}: {
  view: Widget.ChartView;
  availableCharts?: WidgetViewsType;
}) {
  const chart = availableCharts?.find(
    ({ type: availableChartType, subType: availableChartSubType = "" }) =>
      type === availableChartType && subType === availableChartSubType,
  );

  if (!chart) return "widget_chart_disabled";

  return "";
}
