import { useCallback, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { ReferenceArea } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";

import { isNumber } from "src/utils";
import { LIGHT_BLUE } from "src/constants";

// Inner imports
import { LineChartSelection } from "../types";

export const useLineChartSelection = ({
  minValue,
  maxValue,
  callback,
}: {
  minValue?: number;
  maxValue?: number;
  callback?: (value: LineChartSelection) => void;
}) => {
  const [selection, setSelection] = useImmer<LineChartSelection>({
    start: null,
    end: null,
  });

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const isSelectionActive = useMemo<boolean>(
    () => Boolean(selection.start || selection.end),
    [selection.start, selection.end],
  );

  const checkIsValueInRange = useCallback(
    (value: number): boolean => {
      if (minValue && value < minValue) return false;

      if (maxValue && value > maxValue) return false;

      return true;
    },
    [maxValue, minValue],
  );

  const onMouseUp = useCallback((): void => {
    if (selection.start && selection.end && selection.start !== selection.end)
      callback?.(selection);

    setSelection((draft) => {
      draft.start = null;
      draft.end = null;
    });
  }, [callback, selection, setSelection]);

  const onMouseDown = useCallback(
    (event: CategoricalChartState): void => {
      const start = Number(event.activeLabel);

      const isValidValue = isNumber(start) && checkIsValueInRange(start);

      if (!start || !isValidValue || !start) return;

      setSelection((draft) => {
        draft.start = start;
        draft.end = null;
      });
    },
    [checkIsValueInRange, setSelection],
  );

  const onMouseMove = useCallback(
    (event: CategoricalChartState): void => {
      const end = Number(event.activeLabel);

      const isValidValue = isNumber(end) && checkIsValueInRange(end);

      setIsHovered(isValidValue);

      if (!selection.start || !isValidValue || !end) return;

      setSelection((draft) => {
        draft.end = end;
      });
    },
    [checkIsValueInRange, selection.start, setSelection],
  );

  const SelectionElement = useMemo<JSX.Element | null>(() => {
    if (!selection.start || !selection.end) return null;

    return (
      <ReferenceArea
        opacity={0.6}
        fill={LIGHT_BLUE}
        x1={selection.start}
        x2={selection.end}
      />
    );
  }, [selection.start, selection.end]);

  return {
    onMouseUp,
    isHovered,
    onMouseDown,
    onMouseMove,
    SelectionElement,
    isSelectionActive,
  };
};
