import { cloneDeep, set } from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "src/store";
import { selectAvailableDashboardById } from "src/store/selectors";
import { formatDashboardWidgetTileId, showToastNotification } from "src/utils";
import { calculateWidgetLayoutPosition } from "src/utils/calculateWidgetLayoutPosition";
import {
  fetchWidgets,
  updateDashboard,
  writeInitialWidgetStructure,
} from "src/store/actions";

export const useAddWidgetToDashboard = (
  trackersCollectionId: TrackersCollection.Data["id"],
  dashboardDateRangeId: DashboardDateRange.Data["id"],
) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, trackersCollectionId),
  );

  return async (widgetId: Widget.IdType): Promise<void> => {
    if (!dashboard)
      return showToastNotification({
        type: "error",
        text: t("common.error.server_error_reload"),
      });

    const tiles = cloneDeep(dashboard.tiles);

    set(tiles, [widgetId], true);

    const updatedLayouts = Object.entries({ ...dashboard.layouts }).reduce(
      (acc, [layoutSize, layout]) => {
        const newWidgetItem = calculateWidgetLayoutPosition({
          widgetId,
          layoutSize: layoutSize as LayoutSize,
          layout,
        });

        return { ...acc, [layoutSize]: [...layout, newWidgetItem] };
      },
      { ...dashboard.layouts },
    );

    try {
      dispatch(writeInitialWidgetStructure({ dashboardDateRangeId, widgetId }));

      await dispatch(
        updateDashboard({
          id: dashboard.id,
          changes: { tiles, layouts: updatedLayouts },
        }),
      ).unwrap();

      await dispatch(
        fetchWidgets({
          dashboardDateRangeId,
          trackersCollectionId,
          widgetsIds: [widgetId],
        }),
      ).unwrap();

      const widgetTile = document.getElementById(
        formatDashboardWidgetTileId(dashboard, widgetId),
      );

      widgetTile?.scrollIntoView({ block: "center", behavior: "smooth" });
    } catch (error) {
      showToastNotification({
        type: "error",
        text: t("common.error.server_error_reload"),
      });
    }
  };
};
