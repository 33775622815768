import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import compareAsc from "date-fns/compareAsc";
import differenceInDays from "date-fns/differenceInDays";

import { cleanTimeFromDate } from "src/utils";
import { selectEvents } from "src/store/selectors";
import { CHART_COLORS, LIGHT_BLUE } from "src/constants/colors";
import {
  ChartItem,
  LineChartEvent,
  LineChartEvent as LineChartEventType,
} from "../types";

type Props = {
  data: ChartItem[];
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const useLineChartEvents = ({ data, trackersCollectionId }: Props) => {
  const events = useSelector(selectEvents);

  const [selectedEventId, setSelectedEventId] = useState<Event.Data["id"]>("");

  const dashboardActiveEvents = useMemo<Event.Data[]>(() => {
    const filteredEvents = new Set<Event.Data>();

    for (const event of events) {
      const isDashboardEvent = event.dashboardId === trackersCollectionId;

      if (isDashboardEvent && event.isActive) filteredEvents.add(event);
    }

    return [...filteredEvents];
  }, [trackersCollectionId, events]);

  const formattedEvents = useMemo<LineChartEvent[]>(() => {
    const formattedEvents: LineChartEvent[] = [];

    for (const [index, event] of dashboardActiveEvents.entries())
      formattedEvents.push(formatToLineChartEvent(data, event, index));

    return formattedEvents.sort((a, b) =>
      compareAsc(
        differenceInDays(a.startDate, a.endDate),
        differenceInDays(b.startDate, b.endDate),
      ),
    );
  }, [data, dashboardActiveEvents]);

  const selectedEvent = useMemo<LineChartEventType | undefined>(
    () => formattedEvents.find(({ id }) => id === selectedEventId),
    [formattedEvents, selectedEventId],
  );

  const renderedEvents = useMemo<LineChartEventType[]>(() => {
    if (!selectedEvent) return formattedEvents;

    return [selectedEvent];
  }, [formattedEvents, selectedEvent]);

  return {
    selectedEvent,
    renderedEvents,
    selectedEventId,
    setSelectedEventId,
    events: formattedEvents,
  };
};

function formatToLineChartEvent(
  data: ChartItem[],
  event: Event.Data,
  index: number,
): LineChartEvent {
  const lineChartEvent: LineChartEvent = {
    ...event,
    startDate: new Date(cleanTimeFromDate(event.startDate)).getTime(),
    endDate: new Date(cleanTimeFromDate(event.endDate)).getTime(),
    color: CHART_COLORS[index] || LIGHT_BLUE,
  };

  const { startDate, endDate } = lineChartEvent;

  const { minDateTime, maxDateTime } = data.reduce(
    (acc, { time }) => {
      if (time < acc.minDateTime) acc.minDateTime = time + 1;

      if (time > acc.maxDateTime) acc.maxDateTime = time;

      return acc;
    },
    { minDateTime: data[0]?.time || 0, maxDateTime: 0 },
  );

  switch (true) {
    // < ... startDate ... > endDate
    case startDate > minDateTime &&
      startDate < maxDateTime &&
      endDate > maxDateTime:
      return {
        ...lineChartEvent,
        startDate: startDate,
        endDate: maxDateTime,
      };

    // startDate < ... endDate ... >
    case startDate < minDateTime &&
      endDate < maxDateTime &&
      endDate > minDateTime:
      return {
        ...lineChartEvent,
        startDate: minDateTime,
        endDate: endDate,
      };

    // startDate < ... > endDate
    case startDate <= minDateTime && endDate >= maxDateTime:
      return {
        ...lineChartEvent,
        startDate: minDateTime,
        endDate: maxDateTime,
      };

    // startDate endDate < ... > OR < ... > startDate endDate
    case startDate < minDateTime && endDate < minDateTime:
    case startDate > maxDateTime && endDate > maxDateTime:
    default:
      return {
        ...lineChartEvent,
        startDate: startDate,
        endDate: endDate,
      };
  }
}
