import differenceInDays from "date-fns/differenceInDays";
import { Dictionary } from "@reduxjs/toolkit";
import compareAsc from "date-fns/compareAsc";

import {
  isNumber,
  calculateTrend,
  cleanTimeFromDate,
  showDevelopmentError,
  getMaxNumberOfDigitsAfterDecimal,
} from "src/utils";

// Inner imports
import { Values, ChartItem, LineChartEvent, FormattedChartItem } from "./types";

// Inner imports
import { LINE_CHART_TICK_MIN_STEP } from "./constants";

export const getTickStep = (minTick: number, maxTick: number): number => {
  const convertedNumber = Math.floor(Math.log10(maxTick - minTick));

  return Math.max(Math.pow(10, convertedNumber) / 10, LINE_CHART_TICK_MIN_STEP);
};

export const getFormattedDataKey = (
  trackerId: Tracker.Data["id"],
  format: "actual" | "forecast",
): string => {
  switch (format) {
    case "forecast":
      return `${trackerId}_forecast`;
    case "actual":
    default:
      return trackerId;
  }
};

export const formatActualToForecastDataPoint = (
  dataPoint: FormattedChartItem,
  trackerIds: Tracker.Data["id"][],
): FormattedChartItem => {
  const { Trend, date, ...rest } = dataPoint;

  const formattedDataPoint: FormattedChartItem = {
    Trend,
    date,
  };

  for (const trackerId of trackerIds) {
    const formattedKey = getFormattedDataKey(trackerId, "forecast");

    formattedDataPoint[formattedKey] = rest[trackerId];
  }

  return formattedDataPoint;
};

export const calculateNewDataPoint = (
  time: number,
  data: ChartItem[],
): ChartItem | null => {
  const [firstDataPoint, lastDataPoint] = getNearestDataPointsToDate(
    time,
    data,
  );

  if (!firstDataPoint || !lastDataPoint) return null;

  return calculatePointBetweenTwoPoints(time, firstDataPoint, lastDataPoint);
};

export const getDataExtendedWithEvents = (
  data: ChartItem[],
  events: LineChartEvent[],
): ChartItem[] => {
  const [newDates, newDataPoints] = [new Set<number>(), new Set<ChartItem>()];

  for (const { startDate, endDate } of events) {
    const [isStartDatePresent, isEndDatePresent] = [
      getIsDatePresent(data, startDate),
      getIsDatePresent(data, endDate),
    ];

    if (!isStartDatePresent) newDates.add(startDate);

    if (!isEndDatePresent) newDates.add(endDate);
  }

  for (const time of newDates) {
    const newDataPoint = calculateNewDataPoint(time, data);

    if (newDataPoint) newDataPoints.add(newDataPoint);
  }

  return [...data, ...newDataPoints].sort((a, b) => a.time - b.time);
};

export const getDataFilteredWithEvent = (
  data: ChartItem[],
  { startDate, endDate }: LineChartEvent,
): ChartItem[] => {
  const [formattedStartDate, formattedEndDate] = [
    new Date(cleanTimeFromDate(startDate)).getTime(),
    new Date(cleanTimeFromDate(endDate)).getTime(),
  ];

  const filteredData: ChartItem[] = [];

  for (const dataItem of data) {
    const formattedTime = new Date(cleanTimeFromDate(dataItem.time)).getTime();

    const isDataItemInRange =
      formattedTime >= formattedStartDate && formattedTime <= formattedEndDate;

    if (isDataItemInRange) filteredData.push(dataItem);
  }

  return filteredData;
};

export const getTrendLineValues = (
  data: ChartItem[],
): Record<number, number> => {
  const formattedData: { date: number; value: number }[] = [];

  for (const { time, values } of data)
    for (const id in values) {
      const value = values[id] || 0;

      formattedData.push({ date: time, value });
    }

  try {
    const trendLineData = calculateTrend(formattedData, "date", "value");

    const trendLineMap = new Map<number, number>();

    for (const { date, value } of trendLineData) trendLineMap.set(date, value);

    return Object.fromEntries(trendLineMap);
  } catch (error) {
    showDevelopmentError({
      additionalTexts: ["TREND LINE CALCULATION ERROR"],
      error,
    });

    return {};
  }
};

export const getTrendLineMinMaxValues = (
  data: Record<number, number>,
): [number, number] => {
  const values = Object.values(data);

  return [Math.min(...values), Math.max(...values)];
};

export const mapAdditionalValues = (
  trackersEntities: Dictionary<Tracker.Data>,
  additionalValues: Values = {},
): Values => {
  const mappedAdditionalValues: Values = {};

  for (const trackerId in additionalValues) {
    const trackerName = trackersEntities[trackerId]?.name;

    const additionalValue = additionalValues[trackerId];

    if (!trackerName || !isNumber(additionalValue)) continue;

    mappedAdditionalValues[trackerName] = additionalValue;
  }

  return mappedAdditionalValues;
};

function getNearestDataPointsToDate(
  time: number,
  allData: ChartItem[],
): ChartItem[] {
  const dataPoints: ChartItem[] = [];

  const filteredLeftDataPoints = allData.filter((point) => point.time < time);
  const leftNearestDataPoint =
    filteredLeftDataPoints[filteredLeftDataPoints.length - 1];

  if (leftNearestDataPoint) dataPoints.push(leftNearestDataPoint);

  const filteredRightDataPoints = allData.filter((point) => point.time > time);
  const rightNearestDataPoint = filteredRightDataPoints[0];

  if (rightNearestDataPoint) dataPoints.push(rightNearestDataPoint);

  return dataPoints;
}

function calculatePointBetweenTwoPoints(
  time: number,
  firstDataPoint: ChartItem,
  lastDataPoint: ChartItem,
): ChartItem {
  const trackerIds = Object.keys(firstDataPoint.values);

  const [
    {
      time: startTime,
      values: startValues,
      additionalValues: startAdditionalValues = {},
    },
    {
      time: endTime,
      values: endValues,
      additionalValues: endAdditionalValues = {},
    },
  ] = [firstDataPoint, lastDataPoint];

  const calculatedValues: [string, number][] = [];
  const calculatedAdditionalValues: [string, number][] = [];

  const values = [
    {
      start: startValues,
      end: endValues,
      result: calculatedValues,
    },
    {
      start: startAdditionalValues,
      end: endAdditionalValues,
      result: calculatedAdditionalValues,
    },
  ];

  for (const { start, end, result } of values) {
    if (!Object.values(start).length || !Object.values(end).length) continue;

    for (const trackerId of trackerIds) {
      const [brandStartValue, brandEndValue] = [
        start[trackerId] || 0,
        end[trackerId] || 0,
      ];

      const calculatedValue = calculateAverageValue(
        time,
        {
          time: startTime,
          value: brandStartValue,
        },
        {
          time: endTime,
          value: brandEndValue,
        },
      );

      result.push([trackerId, calculatedValue]);
    }
  }

  return {
    ...firstDataPoint,
    time,
    values: Object.fromEntries(calculatedValues),
    ...(calculatedAdditionalValues.length
      ? { additionalValues: Object.fromEntries(calculatedAdditionalValues) }
      : {}),
  };
}

function calculateAverageValue(
  time: number,
  start: { value: number; time: number },
  end: { value: number; time: number },
): number {
  const pointDaysDifference = differenceInDays(time, start.time);

  const dataDaysDifference = differenceInDays(end.time, start.time) || 1;
  const dataValuesDifference = end.value - start.value;
  const dataDifferencePerDay = dataValuesDifference / dataDaysDifference;

  const averageValue = dataDifferencePerDay * pointDaysDifference + start.value;
  const formattedValue = averageValue.toFixed(
    getMaxNumberOfDigitsAfterDecimal([start.value, end.value]),
  );

  return Number(formattedValue);
}

function getIsDatePresent(data: ChartItem[], time: number): boolean {
  const formattedTime = new Date(cleanTimeFromDate(time)).getTime();

  for (const { time } of data) {
    const formatterDataItemTime = new Date(cleanTimeFromDate(time)).getTime();

    const isDatePresent =
      compareAsc(formattedTime, formatterDataItemTime) === 0;

    if (isDatePresent) return true;
  }

  return false;
}
