import { useSelector } from "react-redux";
import isAfter from "date-fns/isAfter";

import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch } from "src/store";
import { usePreviousState } from "src/hooks";
import { parseWidgetSyncId } from "src/utils";
import { changeWidgetStatus, fetchWidgets } from "src/store/actions";
import {
  selectWidgetsEntities,
  selectTrackersCollectionById,
  selectWidgetSyncsByTrackersCollectionId,
} from "src/store/selectors";

export const useDashboardWidgetSyncs = (
  trackersCollectionId: TrackersCollection.Data["id"],
): void => {
  const dispatch = useAppDispatch();

  const widgetSyncs = useSelector((state: Store.RootState) =>
    selectWidgetSyncsByTrackersCollectionId(state, trackersCollectionId),
  );

  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, trackersCollectionId),
  );

  const widgets = useSelector(selectWidgetsEntities);

  const currentWidgetSyncIds = useMemo<WidgetSync.Data["id"][]>(
    () => widgetSyncs.map(({ id }) => id),
    [widgetSyncs],
  );

  const previousWidgetSyncIds = usePreviousState(currentWidgetSyncIds);

  const getWidgetNewStatus = useCallback(
    ({
      widgetId,
      dashboardDateRangeId,
    }: {
      widgetId: Widget.IdType;
      dashboardDateRangeId: DashboardDateRange.Data["id"];
    }): Widget.Status => {
      const widget = widgets[dashboardDateRangeId]?.[widgetId];

      if (!trackersCollection?.updatedAt || !widget?.updatedAt)
        return "calculating";

      try {
        const [formattedTrackersCollectionUpdatedAt, formattedWidgetUpdatedAt] =
          [
            new Date(trackersCollection?.updatedAt),
            new Date(widget?.updatedAt),
          ];

        const isTrackersCollectionUpdated = isAfter(
          formattedTrackersCollectionUpdatedAt,
          formattedWidgetUpdatedAt,
        );

        if (isTrackersCollectionUpdated) return "calculating";

        return "updating";
      } catch (error) {
        console.error(error);

        return "calculating";
      }
    },
    [trackersCollection?.updatedAt, widgets],
  );

  useEffect(() => {
    const widgetSyncIds = new Set<WidgetSync.Data["id"]>([
      ...currentWidgetSyncIds,
      ...(previousWidgetSyncIds || []),
    ]);

    if (!widgetSyncIds.size) return;

    for (const widgetSyncId of widgetSyncIds) {
      try {
        const { widgetId, trackersCollectionId, dashboardDateRangeId } =
          parseWidgetSyncId(widgetSyncId);

        const [isWidgetSyncDeleted, isWidgetSyncAdded] = [
          !currentWidgetSyncIds.includes(widgetSyncId),
          !previousWidgetSyncIds?.includes(widgetSyncId),
        ];

        if (isWidgetSyncDeleted)
          dispatch(
            fetchWidgets({
              dashboardDateRangeId,
              trackersCollectionId,
              widgetsIds: [widgetId],
            }),
          )
            .unwrap()
            .catch(console.error);

        if (isWidgetSyncAdded) {
          const status = getWidgetNewStatus({ widgetId, dashboardDateRangeId });

          dispatch(
            changeWidgetStatus({ widgetId, dashboardDateRangeId, status }),
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [
    dispatch,
    getWidgetNewStatus,
    currentWidgetSyncIds,
    previousWidgetSyncIds,
  ]);
};
