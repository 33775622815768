import firestore from "src/services/firestore";
import { showDevelopmentError, triggerGtmEvent } from "src/utils";

import { getTimestamps } from "../utils";
import { COLLECTION_IDS } from "../constants";

// Inner imports
import * as schemas from "./eventsSchema";

export const getEventsByCompanyId = async (
  companyId: Company.Data["id"],
): Promise<Event.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.events)
    .where("companyId", "==", companyId)
    .get();

  const result: Event.Data[] = [];

  for (const doc of collection.docs) {
    try {
      const event = schemas.eventSchema.validateSync(doc.data());

      result.push({
        ...event,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "EVENT VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return result;
};

export const getEventsByDashboardId = async (
  dashboardId: Dashboard.Data["id"],
): Promise<Event.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.events)
    .where("dashboardId", "==", dashboardId)
    .get();

  const result: Event.Data[] = [];

  for (const doc of collection.docs) {
    try {
      const event = schemas.eventSchema.validateSync(doc.data());

      result.push({
        ...event,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "EVENT VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return result;
};

export const getEventsByIds = async (
  eventIds: Event.Data["id"][],
): Promise<Event.Data[]> => {
  const promises = new Set<
    Promise<firestore.DocumentSnapshot<firestore.DocumentData>>
  >();

  for (const eventId of eventIds) {
    const docRef = firestore()
      .collection(COLLECTION_IDS.events)
      .doc(eventId)
      .get();

    promises.add(docRef);
  }

  const result = await Promise.all(promises);

  const events = new Set<Event.Data>();

  for (const doc of result) {
    try {
      const event = schemas.eventSchema.validateSync(doc.data());

      events.add({ ...event, id: doc.id });
    } catch (error) {
      const errorTitle = "EVENT VALIDATION ERROR";

      showDevelopmentError({ additionalTexts: [errorTitle], error });
    }
  }

  return [...events];
};

export const createEvent = async (
  payload: Store.CreateEntity<Event.Data>,
): Promise<Event.Data> => {
  const { createdAt, updatedAt } = getTimestamps();

  const _payload = {
    ...payload,
    createdAt,
    updatedAt,
  };

  const doc = await firestore().collection(COLLECTION_IDS.events).add(_payload);

  triggerGtmEvent("DashboardCreateEvent", { eventId: doc.id });

  return {
    ..._payload,
    id: doc.id,
  };
};

export const updateEvent = async ({
  id,
  changes,
}: Store.UpdateEntity<Event.Data>): Promise<Store.UpdateEntity<Event.Data>> => {
  const { updatedAt } = getTimestamps();

  const _changes = {
    ...changes,
    updatedAt,
  };

  await firestore()
    .collection(COLLECTION_IDS.events)
    .doc(id)
    .set(_changes, { merge: true });

  return {
    id,
    changes: _changes,
  };
};

export const updateEvents = async (
  payload: Store.UpdateEntity<Event.Data>[],
): Promise<Store.UpdateEntity<Event.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<Event.Data>[] = [];

  const batch = firestore().batch();

  for (const { id, changes } of payload) {
    const _changes = {
      ...changes,
      updatedAt,
    };

    const docRef = firestore().collection(COLLECTION_IDS.events).doc(id);

    batch.set(docRef, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });
  }

  await batch.commit();

  return updatedPayload;
};

export const updateEventsByAuthorId = async (
  {
    changes,
    authorId,
  }: {
    changes: Store.UpdateEntity<Event.Data>["changes"];
    authorId: Event.Data["authorId"];
  },
  companyId: Company.Data["id"],
): Promise<Store.UpdateEntity<Event.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<Event.Data>[] = [];

  const batch = firestore().batch();

  const eventsByAuthorId = await firestore()
    .collection(COLLECTION_IDS.events)
    .where("companyId", "==", companyId)
    .where("authorId", "==", authorId)
    .get();

  for (const { id, ref } of eventsByAuthorId.docs) {
    const _changes = { ...changes, updatedAt };

    batch.set(ref, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });
  }

  await batch.commit();

  return updatedPayload;
};

export const deleteEvent = async (
  id: Event.Data["id"],
): Promise<Event.Data["id"]> => {
  await firestore().collection(COLLECTION_IDS.events).doc(id).delete();

  return id;
};

export const deleteEvents = async (
  ids: Event.Data["id"][],
): Promise<Event.Data["id"][]> => {
  const batch = firestore().batch();

  for (const eventId of ids) {
    const docRef = firestore().collection(COLLECTION_IDS.events).doc(eventId);

    batch.delete(docRef);
  }

  await batch.commit();

  return ids;
};
