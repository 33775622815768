import {
  sortValues,
  stringFilter,
  isKeywordsDataSourceTypeGuard,
} from "src/utils";

export const sortDashboards = ({
  type,
  dashboards,
  lastViewedDashboardIds,
}: {
  type: Dashboard.ListSort;
  dashboards: Dashboard.Data[];
  lastViewedDashboardIds: User.Data["lastViewedTrackerIds"];
}): Dashboard.Data[] => {
  switch (type) {
    case "name":
      return [...dashboards].sort((a, b) => sortValues(a.name, b.name, "ASC"));
    case "createdAt":
      return [...dashboards].sort((a, b) =>
        sortValues(a.createdAt, b.createdAt, "DESC"),
      );
    case "lastViewed": {
      const reversedLastViewedDashboardIds = [
        ...lastViewedDashboardIds,
      ].reverse();

      return [...dashboards].sort(
        (a, b) =>
          reversedLastViewedDashboardIds.indexOf(b.id) -
            reversedLastViewedDashboardIds.indexOf(a.id) ||
          sortValues(a.createdAt, b.createdAt, "DESC"),
      );
    }
    default:
      return dashboards;
  }
};

export const formatDashboardsWithKeywordsDataSources = ({
  trackers,
  dashboards,
  trackersCollections,
}: {
  trackers: Tracker.Data[];
  dashboards: Dashboard.Data[];
  trackersCollections: TrackersCollection.Data[];
}): Dashboard.DataWithKeywordsDataSources[] => {
  const formattedDashboards = new Set<Dashboard.DataWithKeywordsDataSources>();

  for (const dashboard of dashboards) {
    const trackersCollection = trackersCollections.find(
      (value) => value.id === dashboard.id,
    );

    if (!trackersCollection) continue;

    const keywordsDataSources = new Set<Search.KeywordsDataSource>();

    for (const trackerId of trackersCollection.trackerIds) {
      const tracker = trackers.find((value) => value.id === trackerId);

      if (!tracker) continue;

      for (const dataSource of tracker.keywordsDataSources) {
        if (!isKeywordsDataSourceTypeGuard(dataSource)) continue;

        keywordsDataSources.add(dataSource);
      }
    }

    formattedDashboards.add({
      ...dashboard,
      keywordsDataSources: Array.from(keywordsDataSources),
    });
  }

  return Array.from(formattedDashboards);
};

export const filterDashboards = ({
  query,
  authorId,
  dashboards,
  keywordsDataSource,
}: {
  query: string;
  dashboards: Dashboard.DataWithKeywordsDataSources[];
  authorId: Dashboard.Data["authorId"];
  keywordsDataSource: Search.KeywordsDataSource | "all";
}): Dashboard.DataWithKeywordsDataSources[] => {
  const filteredDashboards = new Set<Dashboard.DataWithKeywordsDataSources>();

  const [
    isKeywordsDataSourceFilterActive,
    isAuthorIdFilterActive,
    isQueryFilterActive,
  ] = [keywordsDataSource !== "all", authorId !== "all", Boolean(query)];

  for (const dashboard of dashboards) {
    if (
      isKeywordsDataSourceFilterActive &&
      isKeywordsDataSourceTypeGuard(keywordsDataSource)
    ) {
      const isFilteredByKeywordsDataSource =
        dashboard.keywordsDataSources.includes(keywordsDataSource);

      if (!isFilteredByKeywordsDataSource) continue;
    }

    if (isAuthorIdFilterActive) {
      const isFilteredByMemberId = dashboard.authorId === authorId;

      if (!isFilteredByMemberId) continue;
    }

    if (isQueryFilterActive) {
      const isFilteredBySearchValue = stringFilter(dashboard.name, query);

      if (!isFilteredBySearchValue) continue;
    }

    filteredDashboards.add(dashboard);
  }

  return Array.from(filteredDashboards);
};
