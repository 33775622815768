import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { showDevelopmentError, showToastNotification } from "src/utils";
import { getSuggestedCategories } from "src/store/trackers/trackersApi";

type Props = {
  status: LoadingStatus;
  updateStatusHandler: (status: LoadingStatus) => void;
};

export const useGetSuggestedCategories = ({
  status,
  updateStatusHandler,
}: Props) => {
  const { t } = useTranslation();

  const getDefaultCategory = useCallback(
    (query: string): Tracker.CategoryEntity => ({
      dashboardName: query,
      dashboardDescription: "",
    }),
    [],
  );

  const _getSuggestedCategories = async ({
    query,
    callback,
  }: {
    query: string;
    callback?: (categories: Tracker.CategoryEntity[]) => void;
  }): Promise<Tracker.CategoryEntity[]> => {
    try {
      updateStatusHandler("loading");

      const result = await getSuggestedCategories({ query });

      if (!result.length && query) result.push(getDefaultCategory(query));

      callback?.(result);

      updateStatusHandler("succeeded");

      return result;
    } catch (error) {
      showDevelopmentError({ error });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      updateStatusHandler("failed");

      const result: Tracker.CategoryEntity[] = [];

      if (!result.length && query) result.push(getDefaultCategory(query));

      callback?.(result);

      return result;
    }
  };

  return {
    loadingStatus: status,
    getSuggestedCategories: _getSuggestedCategories,
  };
};
