import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";
import { selectCompanySubscriptionPlan } from "../subscriptionPlans/subscriptionPlansSelector";

// Inner imports
import { widgetsInfoAdapter } from "./widgetsInfoSlice";

export const {
  selectAll: selectWidgetsInfo,
  selectEntities: selectWidgetsInfoEntities,
  selectById: selectWidgetInfoById,
} = widgetsInfoAdapter.getSelectors<RootState>(
  ({ widgetsInfo }) => widgetsInfo,
);

export const selectWidgetsInfoStatus = createDraftSafeSelector(
  ({ widgetsInfo }: Store.RootState) => widgetsInfo,
  (widgetsInfo) => widgetsInfo.status,
);

export const selectActiveWidgets = createDraftSafeSelector(
  selectWidgetsInfo,
  selectCompanySubscriptionPlan,
  (widgetInfo, subPlan) => {
    const activeWidgets = new Map<Widget.IdType, WidgetsInfo.Data>();

    if (subPlan?.hideUnavailableWidgets) {
      for (const widget of widgetInfo) {
        const { id, isActive } = widget;

        const isWidgetAvailable = subPlan.availableWidgetsMap[id];

        if (isActive && isWidgetAvailable) activeWidgets.set(id, widget);
      }
    } else {
      for (const widget of widgetInfo) {
        const { id, isActive } = widget;

        if (isActive) activeWidgets.set(id, widget);
      }
    }

    return activeWidgets;
  },
);

export const selectAvailableWidgetsIdsForOneBrandTracker =
  createDraftSafeSelector(selectWidgetsInfo, (widgetInfo) => {
    const availableWidgetsIdsForOneBrandTracker = new Set<
      Partial<Widget.IdType>
    >();

    for (const widget of widgetInfo) {
      const { id, isAvailableForOneTracker } = widget;

      if (isAvailableForOneTracker) {
        availableWidgetsIdsForOneBrandTracker.add(id);
      }
    }

    return availableWidgetsIdsForOneBrandTracker;
  });
