import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { showDevelopmentError } from "src/utils";

type SetValue<T> = Dispatch<SetStateAction<T>>;

export const useSessionStorage = <T>(
  key: string,
  initialValue: T,
): [T, SetValue<T>] => {
  const readValue = useCallback((): T => {
    if (typeof window === "undefined") return initialValue;

    try {
      const item = window.sessionStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      showDevelopmentError({
        additionalTexts: [`Error reading sessionStorage key “${key}”:`],
        error,
      });
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(readValue());

  const setValueToSessionStorage: SetValue<T> = (value) => {
    if (typeof window == "undefined")
      showDevelopmentError({
        additionalTexts: [
          `Tried setting sessionStorage key “${key}” even though environment is not a client`,
        ],
      });

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to session storage
      window.sessionStorage.setItem(key, JSON.stringify(newValue));

      // We dispatch a custom event so every useSessionStorage hook are notified
      window.dispatchEvent(new Event("session-storage"));
    } catch (error) {
      showDevelopmentError({
        additionalTexts: [`Error setting sessionStorage key “${key}”`],
        error,
      });
    }
  };

  const handleStorageChange = useCallback(
    (event: Event | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue],
  );

  // setValueToSessionStorage will trigger the useEffect and new value share through hooks
  useEffect(() => {
    window.addEventListener("session-storage", handleStorageChange);

    return () =>
      window.removeEventListener("session-storage", handleStorageChange);
  }, [handleStorageChange]);

  return [storedValue, setValueToSessionStorage];
};

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    console.warn("parsing error on", { value });
    return undefined;
  }
}
