import { useMemo, useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTES } from "src/constants";
import {
  selectUser,
  selectCompany,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";

// Inner imports
import type { SettingsTab, SettingsTabOption } from "../types";
import { SETTINGS_TABS, SETTINGS_TABS_DEFAULT_TAB } from "../constants";
import { checkIsStringSettingsTab, getSettingsTabFromUrl } from "../utils";

type ReturnType = {
  tabs: SettingsTabOption[];
  activeTab: SettingsTab;
  ActiveTabContent: FC;
};

export const useSettingsTabs = (): ReturnType => {
  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation();

  const user = useSelector(selectUser);

  const company = useSelector(selectCompany);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const [activeTab, setActiveTab] = useState<SettingsTab>(
    SETTINGS_TABS_DEFAULT_TAB,
  );

  useEffect(() => {
    const tabId = getSettingsTabFromUrl();

    if (!tabId)
      return history.push(
        `${ROUTES.settingsPage}#${SETTINGS_TABS_DEFAULT_TAB}`,
        location.state,
      );

    const { checkIsAvailable } = SETTINGS_TABS[tabId];

    if (checkIsAvailable && !checkIsAvailable(user, company, subscriptionPlan))
      return history.push(
        `${ROUTES.settingsPage}#${SETTINGS_TABS_DEFAULT_TAB}`,
        location.state,
      );

    setActiveTab(tabId);

    history.push(`${ROUTES.settingsPage}#${tabId}`, location.state);
  }, [
    user,
    history,
    company,
    activeTab,
    location.hash,
    location.state,
    subscriptionPlan,
  ]);

  const tabs = useMemo<SettingsTabOption[]>(() => {
    const tabOptions: SettingsTabOption[] = [];

    for (const tabId in SETTINGS_TABS) {
      if (!checkIsStringSettingsTab(tabId)) continue;

      const { label, checkIsAvailable } = SETTINGS_TABS[tabId];

      if (
        checkIsAvailable &&
        !checkIsAvailable(user, company, subscriptionPlan)
      )
        continue;

      tabOptions.push({
        value: tabId,
        label: t(label),
        onSelect: () => history.push(`${ROUTES.settingsPage}#${tabId}`),
      });
    }

    return tabOptions;
  }, [company, history, subscriptionPlan, t, user]);

  const ActiveTabContent = useMemo<FC>(
    () => SETTINGS_TABS[activeTab].Component,
    [activeTab],
  );

  return {
    tabs,
    activeTab,
    ActiveTabContent,
  };
};
