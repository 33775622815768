import { RouteComponentProps } from "react-router-dom";

export const updateQueryParams = (
  value: Record<string, string>,
  history: RouteComponentProps["history"],
): void => {
  const currentQueryParams = new URLSearchParams(history.location.search);

  const queryParams = new URLSearchParams({
    ...Object.fromEntries(currentQueryParams),
    ...value,
  }).toString();

  history.replace(
    `${history.location.pathname}?${queryParams}`,
    history.location.state,
  );
};
