import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import * as api from "./companyApi";
import * as userApi from "../user/userApi";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";
import { selectCompanyId, selectCompanyMembers } from "./companySelector";

export const COMPANY_INITIAL_DATA: Company.Data = {
  id: "",
  name: "",
  subscriptionPlanId: "",
  whiteLabel: "",
  countryCode: "",
  enterpriseDashboards: [],
  createdAt: "",
  updatedAt: "",
  subscriptionPlanUpdatedAt: "",
  subscriptionPlanExpirationDate: "",
  customTrackersLimit: null,
  stripeCustomerId: null,
  stripeSubscriptionId: null,
  members: {},
};

export const companyAdapter = createEntityAdapter<Company.Data>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

const initialState = companyAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchCompany = createAsyncThunk<Company.Data, Company.Data["id"]>(
  "company/fetch-by-id",
  api.getCompanyById,
);

export const fetchExternalCompanyById = createAsyncThunk<
  Company.Data,
  Company.Data["id"]
>("company/fetch-external-by-id", api.getExternalCompanyById);

export const updateCompany = createAsyncThunk<
  Store.UpdateEntity<Company.Data>,
  Store.UpdateEntity<Company.Data>["changes"],
  { state: Store.RootState }
>("company/update-one", async (changes, { getState }) => {
  const state = getState();
  const companyId = selectCompanyId(state);

  const payload = {
    id: companyId,
    changes,
  };

  await api.updateCompany(payload);

  return payload;
});

export const updateCompanyMembersWithEmail = createAsyncThunk<
  Store.UpdateEntity<Company.Data>,
  void,
  { state: Store.RootState }
>("company/update-members-email", async (_, { getState }) => {
  const state = getState();
  const companyId = selectCompanyId(state);
  const members = selectCompanyMembers(state);

  const memberIds = Object.keys(members);

  const result = await userApi.getUsersEmailById(memberIds);

  const changes = {
    members: getMembersUpdatedEmail(members, result),
  };

  return {
    id: companyId,
    changes,
  };
});

export const removeCompanyMember = createAsyncThunk<
  Store.UpdateEntity<Company.Data>,
  Company.Member["id"],
  { state: Store.RootState }
>("company/remove-member", async (id, { getState }) => {
  const state = getState();
  const companyId = selectCompanyId(state);
  const members = selectCompanyMembers(state);

  await userApi.deleteUser(id);

  const changes = {
    members: getFilteredMembers(id, members),
  };

  return {
    id: companyId,
    changes,
  };
});

export const updateCompanyMember = createAsyncThunk<
  Store.UpdateEntity<Company.Data>,
  Store.UpdateEntity<
    Pick<Company.Member, "companyRole" | "firstName" | "lastName">
  >,
  { state: Store.RootState }
>("company/update-member", async (payload, { getState }) => {
  const state = getState();
  const companyId = selectCompanyId(state);
  const members = selectCompanyMembers(state);

  await api.updateCompanyMember(payload);

  return {
    id: companyId,
    changes: {
      members: getUpdatedMembers(payload, members),
    },
  };
});

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompany.pending, onStatePending);
    builder.addCase(fetchCompany.rejected, onStateRejected);
    builder.addCase(fetchCompany.fulfilled, (...args) => {
      companyAdapter.upsertOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(fetchExternalCompanyById.fulfilled, companyAdapter.addOne);

    builder.addCase(updateCompany.rejected, onStateRejected);
    builder.addCase(updateCompany.fulfilled, (...args) => {
      companyAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(updateCompanyMembersWithEmail.rejected, onStateRejected);
    builder.addCase(updateCompanyMembersWithEmail.fulfilled, (...args) => {
      companyAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(removeCompanyMember.rejected, onStateRejected);
    builder.addCase(removeCompanyMember.fulfilled, (...args) => {
      companyAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(updateCompanyMember.rejected, onStateRejected);
    builder.addCase(updateCompanyMember.fulfilled, (...args) => {
      companyAdapter.updateOne(...args);
      onStateFulfilled(...args);
    });
  },
});

export default companySlice.reducer;

// HELPERS
function getMembersUpdatedEmail(
  members: Company.Data["members"],
  emails: Record<User.Data["id"], User.Data["email"]>,
): Company.Data["members"] {
  const _members = { ...members };

  for (const id in _members) {
    const member = _members[id];
    const email = emails[id];

    if (!member || !email) continue;

    _members[id] = {
      ...member,
      email,
    };
  }

  return _members;
}

function getFilteredMembers(
  id: Company.Member["id"],
  members: Company.Data["members"],
): Company.Data["members"] {
  const _members = { ...members };

  delete _members[id];

  return _members;
}

function getUpdatedMembers(
  { id, changes }: Store.UpdateEntity<Pick<Company.Member, "companyRole">>,
  members: Company.Data["members"],
): Company.Data["members"] {
  const _members = { ...members };
  const member = _members[id];

  if (member) {
    _members[id] = {
      ...member,
      ...changes,
    };
  }

  return _members;
}
