import firestore from "src/services/firestore";
import { showDevelopmentError } from "src/utils";
import { COLLECTION_IDS } from "../constants";
import { getTimestamps } from "../utils";
import { triggerUpsertDashboardVector } from "../dashboards/dashboardsApi";

// Inner imports
import trackersCollectionSchema from "./trackersCollectionsSchema";

export const getTrackersCollectionsByCompanyId = async (
  companyId: Company.Data["id"],
): Promise<TrackersCollection.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.trackersCollections)
    .where("companyId", "==", companyId)
    .get();

  const result: TrackersCollection.Data[] = [];

  for (const doc of collection.docs) {
    try {
      const trackersCollection = trackersCollectionSchema.validateSync(
        doc.data(),
      );

      result.push({
        ...trackersCollection,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "TRACKER COLLECTIONS VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return result;
};

export const getTrackersCollectionById = async (
  trackersCollectionId: TrackersCollection.Data["id"],
): Promise<TrackersCollection.Data> => {
  const doc = await firestore()
    .collection(COLLECTION_IDS.trackersCollections)
    .doc(trackersCollectionId)
    .get();

  const trackersCollection = trackersCollectionSchema.validateSync(doc.data());

  return {
    ...trackersCollection,
    id: doc.id,
  };
};

export const createTrackersCollection = async (
  payload: Store.CreateEntity<
    Omit<TrackersCollection.Data, "createdAt" | "updatedAt">
  >,
): Promise<TrackersCollection.Data> => {
  const { createdAt, updatedAt } = getTimestamps();

  const _payload = {
    ...payload,
    createdAt,
    updatedAt,
  };

  const doc = await firestore()
    .collection(COLLECTION_IDS.trackersCollections)
    .add(_payload);

  return { ..._payload, id: doc.id };
};

export const updateTrackersCollection = async ({
  id,
  changes,
}: Store.UpdateEntity<TrackersCollection.Data>): Promise<
  Store.UpdateEntity<TrackersCollection.Data>
> => {
  const { updatedAt } = getTimestamps();

  const _changes = {
    ...changes,
    updatedAt,
  };

  await firestore()
    .collection(COLLECTION_IDS.trackersCollections)
    .doc(id)
    .set(_changes, { merge: true });

  await triggerUpsertDashboardVector(id);

  return {
    id,
    changes: _changes,
  };
};

export const updateTrackersCollections = async (
  payload: Store.UpdateEntity<TrackersCollection.Data>[],
): Promise<Store.UpdateEntity<TrackersCollection.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<TrackersCollection.Data>[] = [];

  const triggerUpsertDashboardVectorPromises: Promise<void>[] = [];

  const batch = firestore().batch();

  for (const { id, changes } of payload) {
    const _changes = { ...changes, updatedAt };

    const docRef = firestore()
      .collection(COLLECTION_IDS.trackersCollections)
      .doc(id);

    batch.set(docRef, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });

    triggerUpsertDashboardVectorPromises.push(triggerUpsertDashboardVector(id));
  }

  await batch.commit();

  Promise.all(triggerUpsertDashboardVectorPromises).catch(console.error);

  return updatedPayload;
};

export const updateTrackersCollectionsByAuthorId = async (
  {
    changes,
    authorId,
  }: {
    changes: Store.UpdateEntity<TrackersCollection.Data>["changes"];
    authorId: TrackersCollection.Data["authorId"];
  },
  companyId: Company.Data["id"],
): Promise<Store.UpdateEntity<TrackersCollection.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<TrackersCollection.Data>[] = [];

  const triggerUpsertDashboardVectorPromises: Promise<void>[] = [];

  const batch = firestore().batch();

  const trackersCollectionsByAuthorId = await firestore()
    .collection(COLLECTION_IDS.trackersCollections)
    .where("companyId", "==", companyId)
    .where("authorId", "==", authorId)
    .get();

  for (const { id } of trackersCollectionsByAuthorId.docs) {
    const _changes = { ...changes, updatedAt };

    const docRef = firestore()
      .collection(COLLECTION_IDS.trackersCollections)
      .doc(id);

    batch.set(docRef, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });

    triggerUpsertDashboardVectorPromises.push(triggerUpsertDashboardVector(id));
  }

  await batch.commit();

  Promise.all(triggerUpsertDashboardVectorPromises).catch(console.error);

  return updatedPayload;
};

export const getTrackersCollectionsWhereTrackerUsed = async (
  trackerId: Tracker.Data["id"],
): Promise<TrackersCollection.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.trackersCollections)
    .where("trackerIds", "array-contains", trackerId)
    .get();

  const result: TrackersCollection.Data[] = [];

  for (const doc of collection.docs) {
    try {
      const trackersCollection = trackersCollectionSchema.validateSync(
        doc.data(),
      );

      result.push({
        ...trackersCollection,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "TRACKER COLLECTIONS VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return result;
};

export const deleteTrackersCollection = (
  id: TrackersCollection.Data["id"],
): Promise<void> =>
  firestore().collection(COLLECTION_IDS.trackersCollections).doc(id).delete();

export const deleteTrackersCollections = (
  trackersCollectionIds: TrackersCollection.Data["id"][],
): Promise<void> => {
  const batch = firestore().batch();

  for (const trackersCollectionId of trackersCollectionIds) {
    const docRef = firestore()
      .collection(COLLECTION_IDS.trackersCollections)
      .doc(trackersCollectionId);

    batch.delete(docRef);
  }

  return batch.commit();
};

export const subscribeOnTrackersCollection = (
  trackersCollectionId: TrackersCollection.Data["id"],
  callback: (trackersCollection: TrackersCollection.Data) => void,
): (() => void) => {
  const docRef = firestore()
    .collection(COLLECTION_IDS.trackersCollections)
    .doc(trackersCollectionId);

  return docRef.onSnapshot((doc) => {
    try {
      const validatedTrackersCollection = trackersCollectionSchema.validateSync(
        doc.data(),
      );

      const trackersCollection = {
        ...validatedTrackersCollection,
        id: doc.id,
      };

      callback(trackersCollection);
    } catch (error) {
      console.error(error);
    }
  });
};
