import { SettingsTab } from "./types";
import { SETTINGS_TAB_IDS } from "./constants";

export const getSettingsTabFromUrl = (): SettingsTab | null => {
  const locationHashString = location.hash.replace("#", "");

  if (!checkIsStringSettingsTab(locationHashString)) return null;

  return locationHashString;
};

export const checkIsStringSettingsTab = (
  string: string,
): string is SettingsTab => SETTINGS_TAB_IDS.includes(string as SettingsTab);

export const checkUserAdminPermission = ({ companyRole }: User.Data): boolean =>
  companyRole === "admin" || companyRole === "owner";

export const checkBillingTabPermission = (
  { companyRole }: User.Data,
  company: Company.Data,
  subscriptionPlan?: SubscriptionPlan.Data,
): boolean => {
  if (!subscriptionPlan || !company) return false;

  if (companyRole !== "admin" && companyRole !== "owner") return false;

  return !subscriptionPlan.isEnterprise;
};
