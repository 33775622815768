import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import styles from "./DashboardPage.module.scss";
import { ROUTES } from "src/constants";
import { useAppDispatch } from "src/store";
import { useQueryParamsState } from "src/hooks";
import { showToastNotification } from "src/utils";
import { PlusCircleFill } from "src/assets/icons";
import { Preloader, Button } from "src/components";
import { DashboardSidebar, WidgetsSidebar } from "src/features";
import { updateUserLastViewedDashboardIds } from "src/store/actions";
import { DashboardPageContext } from "src/pages/Dashboards/DashboardPageWrapper/context";
import {
  selectActiveWidgets,
  selectIsCompanyDashboard,
  selectAvailableDashboardById,
  selectTrackersCollectionById,
} from "src/store/selectors";

// Inner imports
import { OPEN_BUTTON_ID } from "./constants";
import {
  DashboardHead,
  DashboardBody,
  DashboardConfiguration,
  DashboardTrackersAlert,
} from "./components";
import {
  useDashboardWidgetSyncs,
  useDashboardDateRangesLoader,
  useTrackersCollectionObserver,
  useDashboardWidgetSyncsObserver,
} from "./hooks";

type Props = {
  isReadOnly?: boolean;
  dashboardId: Dashboard.Data["id"];
};

const DashboardPage: FC<Props> = ({ dashboardId, isReadOnly: _isReadOnly }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { setSidebarContent } = useContext(DashboardPageContext);

  const { state } = useLocation<LocationState>();

  const dispatch = useAppDispatch();

  const activeWidgets = useSelector(selectActiveWidgets);

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, dashboardId),
  );

  const isReadOnly = useMemo<boolean>(
    () => _isReadOnly ?? state?.isReadOnly ?? false,
    [_isReadOnly, state?.isReadOnly],
  );

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId, isReadOnly),
  );

  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, dashboardId),
  );

  const { tiles, layoutView } = useMemo(
    () => ({
      tiles: dashboard?.tiles || {},
      layoutView: dashboard?.layoutView || "full",
    }),
    [dashboard],
  );

  const trackerIds = useMemo<Tracker.Data["id"][]>(
    () => trackersCollection?.trackerIds || [],
    [trackersCollection?.trackerIds],
  );

  const [isTrackersCollectionObserverSet] = useTrackersCollectionObserver(
    dashboard?.trackersCollectionId,
    isReadOnly,
  );

  const { loadingStatus: dashboardDateRangesLoadingStatus } =
    useDashboardDateRangesLoader({ trackersCollectionId: dashboardId });

  useDashboardWidgetSyncsObserver(dashboardId);

  useDashboardWidgetSyncs(dashboardId);

  const [expandedWidgetId, setExpandedWidgetId] =
    useState<Widget.IdType | null>(null);

  const [activeTrackerIds, setActiveTrackerIds] =
    useState<Tracker.Data["id"][]>(trackerIds);

  const [queryParams, setQueryParams] = useQueryParamsState();

  const dashboardDateRangeId = useMemo<DashboardDateRange.Data["id"]>(
    () => queryParams.dateRangeId || "",
    [queryParams.dateRangeId],
  );

  useEffect(() => {
    if (!isCompanyDashboard) return;

    dispatch(
      updateUserLastViewedDashboardIds({
        lastViewedDashboardIds: [dashboardId],
      }),
    )
      .unwrap()
      .catch();
  }, [dispatch, isCompanyDashboard, dashboardId]);

  useEffect(() => {
    if (!dashboardId) return;

    setExpandedWidgetId(null);
  }, [dashboardId]);

  useEffect(() => {
    if (trackerIds.length) setActiveTrackerIds(trackerIds);
  }, [trackerIds]);

  const isDashboardDateRangesLoaded = useMemo<boolean>(
    () =>
      dashboardDateRangesLoadingStatus === "succeeded" ||
      dashboardDateRangesLoadingStatus === "failed",
    [dashboardDateRangesLoadingStatus],
  );

  const isAddWidgetButtonShown = useMemo<boolean>(() => {
    if (
      !isCompanyDashboard ||
      expandedWidgetId ||
      layoutView !== "full" ||
      isReadOnly
    )
      return false;

    for (const [widgetId] of activeWidgets) {
      const isWidgetAdded = tiles[widgetId];

      if (!isWidgetAdded) return true;
    }

    return false;
  }, [
    tiles,
    layoutView,
    isReadOnly,
    activeWidgets,
    expandedWidgetId,
    isCompanyDashboard,
  ]);

  const setDashboardDateRangeId = (
    value: DashboardDateRange.Data["id"],
  ): void => setQueryParams({ dateRangeId: value });

  const onAddWidgetButtonClick = (): void =>
    setSidebarContent(
      <WidgetsSidebar
        dashboardId={dashboardId}
        dashboardDateRangeId={dashboardDateRangeId}
      />,
    );

  const setActiveTrackerIdsHandler = (value: Tracker.Data["id"][]): void =>
    setActiveTrackerIds((state) => {
      if (value.length > 0) return value;

      showToastNotification({
        type: "info",
        id: "dashboard-tracker-min-info",
        text: t("chart.line.status.warning.minimum_trackers"),
      });

      return state;
    });

  const onSelectDashboardDateRange = (
    value: DashboardDateRange.Data["id"],
  ): void => setDashboardDateRangeId(value);

  const onEditTrackerClick = ({ id }: Tracker.Data): void =>
    history.push(`${ROUTES.trackersPage}/edit/${id}`, {
      redirectUrl: history.location.pathname,
    });

  if (!dashboard) return <Redirect to={ROUTES.dashboardsHomePage} />;

  if (!isTrackersCollectionObserverSet || !isDashboardDateRangesLoaded)
    return <Preloader className={styles.preloader} />;

  return (
    <div className={styles.wrapper}>
      <DashboardSidebar />
      <DashboardHead
        isReadOnly={isReadOnly}
        trackersCollectionId={dashboardId}
        setExpandedWidgetId={setExpandedWidgetId}
        dashboardDateRangeId={dashboardDateRangeId}
      />
      <DashboardConfiguration
        isReadOnly={isReadOnly}
        trackersCollectionId={dashboardId}
        activeTrackerIds={activeTrackerIds}
        dashboardDateRangeId={dashboardDateRangeId}
        setActiveTrackerIdsHandler={setActiveTrackerIdsHandler}
        selectDashboardDateRangeHandler={onSelectDashboardDateRange}
      />
      <DashboardTrackersAlert
        isReadOnly={isReadOnly}
        trackersCollectionId={dashboardId}
        editTrackerHandler={onEditTrackerClick}
      />
      <DashboardBody
        key={dashboard.id}
        isReadOnly={isReadOnly}
        trackersCollectionId={dashboardId}
        expandedWidgetId={expandedWidgetId}
        activeTrackerIds={activeTrackerIds}
        setExpandedWidgetId={setExpandedWidgetId}
        dashboardDateRangeId={dashboardDateRangeId}
      />
      {isAddWidgetButtonShown && (
        <div className={styles.addWidgetButtonWrapper}>
          <Button
            buttonType="dark"
            id={OPEN_BUTTON_ID}
            onClick={onAddWidgetButtonClick}
          >
            <PlusCircleFill />
            <span>{t("page.dashboard.button.include_widget")}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
