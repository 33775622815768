import { FC, useMemo, useState } from "react";
import add from "date-fns/add";
import sub from "date-fns/sub";
import { useImmer } from "use-immer";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { useSelector } from "react-redux";
import isSameDay from "date-fns/isSameDay";
import endOfMonth from "date-fns/endOfMonth";
import { useTranslation } from "react-i18next";

import styles from "./CreateDashboardDateRangeModal.module.scss";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { useModal, useTemporaryErrors } from "src/hooks";
import { createDashboardDateRange } from "src/store/actions";
import { ConfirmModal, CreateEventModal } from "src/features";
import { formatToYearMonth, showToastNotification } from "src/utils";
import { Button, Form, Label, MonthSelector } from "src/components";
import {
  selectUserId,
  selectCompanyId,
  selectKeywordsDataSourcesLatestDataDate,
  selectTrackersCollectionKeywordsDataSources,
  selectDashboardDateRangesByTrackersCollectionId,
} from "src/store/selectors";

const MonthSelectorWithError = withError(MonthSelector);

type Props = {
  endDate?: DashboardDateRange.Data["endDate"];
  startDate?: DashboardDateRange.Data["startDate"];
  trackersCollectionId: TrackersCollection.Data["id"];
  selectDashboardDateRangeIdHandler: (
    value: DashboardDateRange.Data["id"],
  ) => void;
};

export const CreateDashboardDateRangeModal: FC<Props> = ({
  endDate: defaultEndDate,
  startDate: defaultStartDate,
  trackersCollectionId,
  selectDashboardDateRangeIdHandler,
}) => {
  const { t } = useTranslation();

  const { setModal, closeModal } = useModal();

  const dispatch = useAppDispatch();

  const { errors, setErrors } = useTemporaryErrors();

  const userId = useSelector(selectUserId);

  const companyId = useSelector(selectCompanyId);

  const dashboardDateRanges = useSelector((state: Store.RootState) =>
    selectDashboardDateRangesByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const keywordsDataSources = useSelector((state: Store.RootState) =>
    selectTrackersCollectionKeywordsDataSources(state, trackersCollectionId),
  );

  const keywordsDataSourcesLatestDate = useSelector((state: Store.RootState) =>
    selectKeywordsDataSourcesLatestDataDate(state, keywordsDataSources),
  );

  const defaultDashboardDateRange = useMemo<
    Omit<DashboardDateRange.Custom, "id">
  >(() => {
    let startDate: DashboardDateRange.Data["startDate"];
    let endDate: DashboardDateRange.Data["endDate"];

    if (defaultStartDate && defaultEndDate) {
      startDate = formatToYearMonth(defaultStartDate);
      endDate = formatToYearMonth(defaultEndDate);
    } else {
      const currentDate = keywordsDataSourcesLatestDate
        ? new Date(keywordsDataSourcesLatestDate)
        : new Date();

      const previousMonth = sub(currentDate, { months: 1 });

      startDate = formatToYearMonth(previousMonth);
      endDate = formatToYearMonth(currentDate);
    }

    return {
      endDate,
      startDate,
      companyId,
      type: null,
      createdAt: "",
      updatedAt: "",
      authorId: userId,
      category: "custom",
      trackersCollectionId,
    };
  }, [
    userId,
    companyId,
    defaultEndDate,
    defaultStartDate,
    trackersCollectionId,
    keywordsDataSourcesLatestDate,
  ]);

  const [dashboardDateRange, setDashboardDateRange] = useImmer<
    Omit<DashboardDateRange.Custom, "id">
  >(defaultDashboardDateRange);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const minDate = useMemo<string>(() => {
    const formattedDate = new Date(dashboardDateRange.startDate);

    return add(formattedDate, { months: 1 }).toISOString();
  }, [dashboardDateRange.startDate]);

  const existingDashboardDateRange = useMemo<
    DashboardDateRange.Data | undefined
  >(() => {
    const [formattedStartDate, formattedEndDate] = [
      new Date(dashboardDateRange.startDate),
      new Date(dashboardDateRange.endDate),
    ];

    return dashboardDateRanges.find(({ startDate, endDate }) => {
      if (!startDate || !endDate) return;

      return (
        isSameDay(new Date(startDate), formattedStartDate) &&
        isSameDay(new Date(endDate), formattedEndDate)
      );
    });
  }, [
    dashboardDateRanges,
    dashboardDateRange.endDate,
    dashboardDateRange.startDate,
  ]);

  const submitButtonLabel = useMemo<string>(
    () =>
      existingDashboardDateRange
        ? t("component.modal.create_dashboard_date_range.button.view")
        : t("component.modal.create_dashboard_date_range.button.submit"),
    [existingDashboardDateRange, t],
  );

  const onStartDateChange = (value: string): void => {
    setDashboardDateRange((draft) => {
      draft.startDate = value;
    });

    const [formattedStartDate, formattedEndDate] = [
      new Date(value),
      new Date(dashboardDateRange.endDate),
    ];

    if (!isBefore(formattedStartDate, formattedEndDate))
      setDashboardDateRange((draft) => {
        const formattedValue = new Date(value);

        draft.endDate = add(formattedValue, { months: 1 }).toISOString();
      });
  };

  const onEndDateChange = (value: string): void =>
    setDashboardDateRange((draft) => {
      draft.endDate = value;
    });

  const onCreateEvent = (): void => {
    const [formattedStartDate, formattedEndDate] = [
      endOfMonth(new Date(dashboardDateRange.startDate)).toISOString(),
      endOfMonth(new Date(dashboardDateRange.endDate)).toISOString(),
    ];

    setModal(
      <CreateEventModal
        endDate={formattedEndDate}
        startDate={formattedStartDate}
        dashboardId={trackersCollectionId}
      />,
    );
  };

  const onSubmit = async (): Promise<void> => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    if (existingDashboardDateRange) {
      selectDashboardDateRangeIdHandler(existingDashboardDateRange.id);

      return closeModal();
    }

    try {
      setLoadingStatus("loading");

      const { id: dashboardDateRangeId } = await dispatch(
        createDashboardDateRange(dashboardDateRange),
      ).unwrap();

      setLoadingStatus("succeeded");

      showToastNotification({
        type: "success",
        text: t(
          "component.modal.create_dashboard_date_range.form.status.success.dashboard_date_range_created",
        ),
      });

      selectDashboardDateRangeIdHandler(dashboardDateRangeId);

      closeModal();
    } catch (error) {
      setLoadingStatus("failed");

      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  function validate(): Errors {
    const validationErrors: typeof errors = {};

    const { startDate, endDate } = dashboardDateRange;

    const [formattedStartDate, formattedEndDate] = [
      new Date(startDate),
      new Date(endDate),
    ];

    if (isAfter(formattedStartDate, formattedEndDate))
      validationErrors.startDate = t(
        "component.modal.create_dashboard_date_range.form.validation.incorrect_date",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      type="success"
      isLoading={isLoading}
      acceptButton={{ text: submitButtonLabel, onClick: onSubmit }}
      title={t("component.modal.create_dashboard_date_range.title")}
      cancelButton={{
        text: t("component.modal.create_dashboard_date_range.button.cancel"),
        onClick: closeModal,
      }}
    >
      <Form onSubmit={onSubmit} className={styles.formWrapper}>
        <div className={styles.groupWrapper}>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t(
                "component.modal.create_dashboard_date_range.form.label.start_date",
              )}
            />
            <MonthSelectorWithError
              error={errors.startDate}
              onChange={onStartDateChange}
              value={dashboardDateRange.startDate}
              maxValue={keywordsDataSourcesLatestDate}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t(
                "component.modal.create_dashboard_date_range.form.label.end_date",
              )}
            />
            <MonthSelectorWithError
              minValue={minDate}
              error={errors.endDate}
              onChange={onEndDateChange}
              value={dashboardDateRange.endDate}
              maxValue={keywordsDataSourcesLatestDate}
            />
          </div>
        </div>
        <div className={styles.textWrapper}>
          <span>
            {t(
              "component.modal.create_dashboard_date_range.form.label.new_event",
            )}
          </span>
          <Button
            type="button"
            buttonSize="small"
            onClick={onCreateEvent}
            buttonStyle="transparent"
          >
            <span>
              {t(
                "component.modal.create_dashboard_date_range.button.create_event",
              )}
            </span>
          </Button>
        </div>
      </Form>
    </ConfirmModal>
  );
};
