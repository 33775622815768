import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useModal } from "src/hooks";
import { useAppDispatch } from "src/store";
import { showToastNotification } from "src/utils";
import { removeDashboardDateRange } from "src/store/actions";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

type Props = {
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const ConfirmDeleteDashboardDateRangeModal: FC<Props> = ({
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const onSubmit = async (): Promise<void> => {
    try {
      setLoadingStatus("loading");

      await dispatch(removeDashboardDateRange(dashboardDateRangeId)).unwrap();

      setLoadingStatus("succeeded");

      showToastNotification({
        type: "success",
        text: t(
          "component.modal.delete_dashboard_date_range.status.success.dashboard_date_range_deleted",
        ),
      });

      closeModal();
    } catch (error) {
      console.error(error);

      setLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  return (
    <ConfirmModal
      type="warning"
      title={t("component.modal.delete_dashboard_date_range.title")}
      isLoading={isLoading}
      acceptButton={{
        text: t("component.modal.delete_dashboard_date_range.button.submit"),
        buttonType: "error",
        onClick: onSubmit,
      }}
      cancelButton={{
        text: t("component.modal.delete_dashboard_date_range.button.cancel"),
        onClick: closeModal,
      }}
    >
      <span>{t("component.modal.delete_dashboard_date_range.body")}</span>
    </ConfirmModal>
  );
};
