import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

export const formatToMonthDayYearDate = (
  date: string | number | Date,
): string => formatDate(date, "MMM dd, yy");

export const formatToMonthDayFullYearDate = (
  date: string | number | Date,
): string => formatDate(date, "MMM dd, yyyy");

export const formatToDayMonthFullYearDate = (
  date: string | number | Date,
): string => formatDate(date, "dd.MM.yyyy");

export const formatToDayMonthYearDate = (
  date: string | number | Date,
): string => formatDate(date, "dd.MM.yy");

export const formatToMonthYearDate = (date: string | number | Date): string =>
  formatDate(date, "MMM, yy");

export const formatToMonthFullYearDate = (
  date: string | number | Date,
): string => formatDate(date, "MMM, yyyy");

export const formatToDayMonthFullYearTimeDate = (
  date: string | number | Date,
): string => formatDate(date, "dd.MM.yyyy, HH:mm");

export const formatToYearMonth = (date: string | number | Date): string =>
  formatDate(date, "yyyy-MM");

export const formatToYearMonthDay = (date: string | number | Date): string =>
  formatDate(date, "yyyy-MM-dd");

export const formatToUniversalTime = (
  _date: string | number | Date,
): string => {
  try {
    const date = new Date(_date);

    return date.toString();
  } catch (error) {
    console.error(error);

    return "";
  }
};

export const formatToDistanceToNow = (
  _date: string | number | Date,
): string => {
  try {
    const date = new Date(_date);

    return formatDistanceToNow(date, { addSuffix: true });
  } catch (err) {
    console.error(err);

    return "";
  }
};

export const formatStringToDate = (
  value?: string,
  defaultValue: Date | null = null,
): Date | null => {
  if (!value) return defaultValue;

  try {
    return new Date(value);
  } catch (error) {
    console.error(error);

    return defaultValue;
  }
};

export const cleanTimeFromDate = (date: string | number | Date): string =>
  formatDate(date, "yyyy-MM-dd");

function formatDate(_date: string | number | Date, template: string): string {
  try {
    const date = new Date(_date);

    return format(date, template);
  } catch (err) {
    console.error(err);

    return "";
  }
}
