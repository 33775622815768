import { FC, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./ConfirmModal.module.scss";
import { Button } from "src/components";
import * as icons from "src/assets/icons";
import {
  useModal,
  useKeyboardListener,
  useElementOutsideClick,
} from "src/hooks";

// Inner imports
import { ConfirmModalProps } from "./types";

export const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  children,
  className = "",
  isLoading,
  cancelButton,
  acceptButton,
  extraButtons,
  buttonsWrapperClassName = "",
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const ref = useElementOutsideClick(closeModal);

  useKeyboardListener({
    key: "Escape",
    type: "keydown",
    callback: () => !isLoading && closeModal(),
  });

  const hasModalButtons = useMemo<boolean>(
    () => !!(cancelButton || acceptButton || extraButtons?.length),
    [acceptButton, cancelButton, extraButtons],
  );

  return (
    <div className={cx(styles.confirmModal, className)} ref={ref}>
      <Button
        className={styles.closeModalButton}
        onClick={closeModal}
        disabled={isLoading}
      >
        <icons.Cross />
      </Button>
      {title && (
        <div className={styles.title} title={title}>
          <span>{title}</span>
        </div>
      )}
      <div className={styles.content}>{children}</div>
      {hasModalButtons && (
        <div className={cx(styles.buttonsWrapper, buttonsWrapperClassName)}>
          {Boolean(extraButtons?.length) && (
            <div className={styles.extraButtonsWrapper}>
              {extraButtons?.map((button, index) => {
                const Icon = button.icon && icons[button.icon];

                return (
                  <Button
                    type="button"
                    key={`${button.text}-${index}`}
                    className={cx(styles.extraButton, button.className)}
                    onClick={button.onClick}
                    style={{ backgroundColor: button.color ?? "" }}
                    disabled={isLoading || button.disabled}
                  >
                    {!!Icon && <Icon />}
                    {button.text ?? ""}
                  </Button>
                );
              })}
            </div>
          )}
          {cancelButton && (
            <Button
              {...cancelButton}
              type="button"
              className={cx(styles.cancelButton, cancelButton.className || "")}
              buttonSize={cancelButton.buttonSize}
              buttonStyle={cancelButton.buttonStyle || "outlined"}
              disabled={isLoading || cancelButton.disabled}
            >
              {cancelButton.text ?? t("tp_modal_wrapper_cancel_button")}
            </Button>
          )}
          {acceptButton && (
            <Button
              {...acceptButton}
              autoFocus
              type="button"
              className={cx(styles.acceptButton, acceptButton?.className || "")}
              onClick={acceptButton.onClick}
              buttonSize={acceptButton.buttonSize}
              buttonStyle={acceptButton.buttonStyle}
              disabled={isLoading || acceptButton.disabled}
            >
              {isLoading ? (
                <icons.Loader className={styles.loader} />
              ) : (
                acceptButton.text ?? t("tp_modal_wrapper_accept_button")
              )}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
