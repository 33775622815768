import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SubscriptionPlanUsage.module.scss";
import { UpdatePlanButton } from "src/features";
import { ProgressBar, Tooltip, Translation } from "src/components";
import {
  selectCompany,
  selectTrackersLimit,
  selectCompanyTrackers,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";

export const SubscriptionPlanUsage: FC = () => {
  const { t } = useTranslation();

  const trackers = useSelector(selectCompanyTrackers);

  const { stripeCustomerId, stripeSubscriptionId } = useSelector(selectCompany);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const { trackersLimit } = useSelector(selectTrackersLimit);

  const trackersCountLabel = useMemo<string>(() => {
    if (!trackersLimit) return "";

    return t("page.settings.subscription_plan.label.trackers_limit", {
      count: trackers.length,
      totalCount: trackersLimit,
    });
  }, [t, trackers.length, trackersLimit]);

  const trackersPercentValue = useMemo<number>(() => {
    if (!trackers.length) return 0;

    if (!trackersLimit) return 0;

    return trackers.length / trackersLimit;
  }, [trackers.length, trackersLimit]);

  const isTrackersLimitUpgradeAvailable = useMemo<boolean>(() => {
    if (!subscriptionPlan || !stripeCustomerId || !stripeSubscriptionId)
      return false;

    return (
      subscriptionPlan.category !== "free" &&
      subscriptionPlan.category !== "trial"
    );
  }, [stripeCustomerId, stripeSubscriptionId, subscriptionPlan]);

  if (!subscriptionPlan) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            <span>{t("page.settings.subscription_plan.label.trackers")}</span>
            <span>
              {trackersCountLabel ||
                t("page.settings.subscription_plan.label.not_available")}
            </span>
          </div>
          <Tooltip
            content={
              <Translation
                i18nKey="page.settings.subscription_plan.label.trackers_statistic"
                values={{ trackersLimit, trackersCount: trackers.length }}
              />
            }
          >
            <ProgressBar value={trackersPercentValue} />
          </Tooltip>
        </div>
      </div>
      {isTrackersLimitUpgradeAvailable && (
        <UpdatePlanButton className={styles.button} buttonStyle="outlined">
          <span>
            {t("page.settings.subscription_plan.button.update_trackers")}
          </span>
        </UpdatePlanButton>
      )}
    </div>
  );
};
