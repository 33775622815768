import { useMemo } from "react";

import { isNumber } from "src/utils";

// Inner imports
import { RawChartItem, ChartItem } from "../types";

type Props = {
  data: RawChartItem[];
};

type ReturnType = {
  data: ChartItem[];
  forecastStartDate: number;
};

export const usePreparedLineChartData = ({ data }: Props): ReturnType => {
  const { data: formattedData, forecastData } = useMemo(() => {
    const [actualData, forecastData] = [
      new Set<ChartItem>(),
      new Set<ChartItem>(),
    ];

    for (const item of data) {
      try {
        const time = Date.parse(item.date);

        if (!isNumber(time)) continue;

        const formattedItem = { ...item, time };

        switch (true) {
          case item.isForecasted:
            forecastData.add(formattedItem);

            break;
          default:
            actualData.add(formattedItem);

            break;
        }
      } catch (err) {
        console.error(err);
      }
    }

    const [sortedActualData, sortedForecastData] = [
      [...actualData].sort((a, b) => a.time - b.time),
      [...forecastData].sort((a, b) => a.time - b.time),
    ];

    if (sortedForecastData.length) {
      const lastActualDataItem = sortedActualData[sortedActualData.length - 1];

      if (lastActualDataItem)
        sortedForecastData.unshift({
          ...lastActualDataItem,
          time: lastActualDataItem.time + 1,
          isForecasted: true,
        });
    }

    return {
      data: [...sortedActualData, ...sortedForecastData],
      actualData: sortedActualData,
      forecastData: sortedForecastData,
    };
  }, [data]);

  const forecastStartDate = useMemo<number>(() => {
    let forecastStartDate = 0;

    const firstForecastItem = forecastData[0];

    if (firstForecastItem) forecastStartDate = firstForecastItem.time;

    return forecastStartDate;
  }, [forecastData]);

  return {
    data: formattedData,
    forecastStartDate,
  };
};
