import isValidDate from "date-fns/isValid";
import compareAsc from "date-fns/compareAsc";

import { showDevelopmentError } from "./showDevelopmentError";

export const sortValues = (
  valueA: unknown,
  valueB: unknown,
  sortDirection: SortDirection,
): number => {
  const [firstValue, secondValue] =
    sortDirection === "ASC" ? [valueA, valueB] : [valueB, valueA];

  try {
    // [string, string]
    if (isString(firstValue) && isString(secondValue)) {
      const [firstString, secondString] = [
        String(firstValue),
        String(secondValue),
      ];

      const [isFirstValueDate, isSecondValueDate] = [
        isDate(firstString),
        isDate(secondString),
      ];

      // [string, string] && [date, date]
      if (isFirstValueDate && isSecondValueDate) {
        const [firstDateInMs, secondDateInMs] = [
          Date.parse(firstString),
          Date.parse(secondString),
        ];

        return compareAsc(firstDateInMs, secondDateInMs);
      }

      // [string, string] && [!date, !date]
      if (!isFirstValueDate && !isSecondValueDate) {
        const [firstStringLowerCase, secondStringLowerCase] = [
          firstString.toLowerCase(),
          secondString.toLowerCase(),
        ];

        return firstStringLowerCase.localeCompare(secondStringLowerCase);
      }
    }

    // [boolean, boolean]
    if (isBoolean(firstValue) && isBoolean(secondValue)) {
      const [firstBooleanAsNumber, secondBooleanAsNumber] = [
        Number(firstValue),
        Number(secondValue),
      ];

      return firstBooleanAsNumber - secondBooleanAsNumber;
    }

    // [number, number]
    if (isNumber(firstValue) && isNumber(secondValue)) {
      const [firstNumber, secondNumber] = [
        Number(firstValue),
        Number(secondValue),
      ];

      return firstNumber - secondNumber;
    }
  } catch (error) {
    const errorTitle = `SORTING ERROR. VALUES: ${valueA}, ${valueB}`;

    showDevelopmentError({
      additionalTexts: [errorTitle],
      error,
    });
  }

  return 0;
};

function isString(value: unknown): boolean {
  return typeof value === "string";
}

function isBoolean(value: unknown): boolean {
  return typeof value === "boolean";
}

function isNumber(value: unknown): boolean {
  return typeof value === "number" && Number.isFinite(value);
}

function isDate(value: unknown): boolean {
  if (typeof value !== "string" && typeof value !== "number") return false;

  return isValidDate(value);
}
