import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./ShareDashboard.module.scss";
import { ROUTES } from "src/constants";
import { useAppDispatch } from "src/store";
import { triggerGtmEvent } from "src/utils";
import { useGlobalPreloader } from "src/hooks";
import { selectAvailableDashboardById } from "src/store/selectors";
import { getDashboardDataById } from "src/store/dashboards/dashboardsApi";
import {
  fetchEventsByIds,
  fetchTrackersByIds,
  fetchDashboardById,
  fetchExternalCompanyById,
  fetchTrackersCollectionById,
  fetchDashboardDateRangesByIds,
  fetchSubscriptionPlanByCompanyId,
} from "src/store/actions";
import DashboardPage from "../../Dashboards/DashboardPage/DashboardPage";

const ShareDashboard: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const { dashboardId = "" } = useParams<UrlParams>();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId, true),
  );

  const initialLoadingStatus = useMemo<LoadingStatus>(
    () => (dashboard ? "succeeded" : "idle"),
    [dashboard],
  );

  const [loadingStatus, setLoadingStatus] =
    useState<LoadingStatus>(initialLoadingStatus);

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "idle" || loadingStatus === "loading",
    [loadingStatus],
  );

  useEffect(() => {
    if (dashboard || loadingStatus !== "idle") return;

    setLoadingStatus("loading");

    showGlobalPreloader(
      t("page.explore_trend.suggested_dashboard.loader.download_dashboard"),
    );

    getDashboardDataById(dashboardId)
      .then(({ eventIds, dashboardDateRangeIds, trackerIds }) =>
        dispatch(fetchDashboardById(dashboardId))
          .unwrap()
          .then(async ({ companyId }) => {
            await Promise.all([
              dispatch(fetchEventsByIds(eventIds)).unwrap(),
              dispatch(fetchTrackersByIds(trackerIds)).unwrap(),
              dispatch(fetchExternalCompanyById(companyId)).unwrap(),
              dispatch(fetchTrackersCollectionById(dashboardId)).unwrap(),
              dispatch(fetchSubscriptionPlanByCompanyId(companyId)).unwrap(),
              dispatch(
                fetchDashboardDateRangesByIds(dashboardDateRangeIds),
              ).unwrap(),
            ]);
          })
          .then(() => {
            triggerGtmEvent("SharedDashboardView", { dashboardId });

            setLoadingStatus("succeeded");
          }),
      )
      .catch((error) => {
        console.error(error);

        setLoadingStatus("failed");
      })
      .finally(() => {
        hideGlobalPreloader();
      });
  }, [
    t,
    dispatch,
    dashboard,
    dashboardId,
    loadingStatus,
    showGlobalPreloader,
    hideGlobalPreloader,
  ]);

  if (isLoading) return null;

  if (
    loadingStatus === "failed" ||
    (loadingStatus === "succeeded" && !dashboard)
  )
    return <Redirect to={ROUTES.dashboardsHomePage} />;

  return (
    <div className={styles.wrapper}>
      <DashboardPage dashboardId={dashboardId} isReadOnly />
    </div>
  );
};

export default ShareDashboard;
