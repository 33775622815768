import { FC, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DashboardDateRanges.module.scss";
import { Select } from "src/components";
import { formatToMonthFullYearDate } from "src/utils";
import { DASHBOARD_DATE_RANGE_LABEL } from "src/constants";
import { SelectOption } from "src/components/selectors/Select/types";
import {
  selectHasDashboardAccess,
  selectIsCompanyDashboard,
  selectDashboardDateRangesByTrackersCollectionId,
  selectDefaultDashboardDateRangeByTrackersCollectionId,
} from "src/store/selectors";

// Inner imports
import { sortDashboardDateRanges } from "./utils";
import { DashboardDateRangeActions, CreateDashboardDateRangeForm } from "..";

type Props = {
  isReadOnly: boolean;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  selectDashboardDateRangeIdHandler: (
    value: DashboardDateRange.Data["id"],
  ) => void;
};

export const DashboardDateRanges: FC<Props> = ({
  isReadOnly,
  trackersCollectionId,
  dashboardDateRangeId,
  selectDashboardDateRangeIdHandler,
}) => {
  const { t } = useTranslation();

  const { hasDashboardAccess } = useSelector(selectHasDashboardAccess);

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, trackersCollectionId),
  );

  const dashboardDateRanges = useSelector((state: Store.RootState) =>
    selectDashboardDateRangesByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const defaultDashboardDateRange = useSelector((state: Store.RootState) =>
    selectDefaultDashboardDateRangeByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const sortedDashboardDateRanges = useMemo<DashboardDateRange.Data[]>(
    () => [...dashboardDateRanges].sort(sortDashboardDateRanges),
    [dashboardDateRanges],
  );

  const isAddDashboardDateRangeButtonShown = useMemo<boolean>(
    () => isCompanyDashboard && !isReadOnly && hasDashboardAccess,
    [isCompanyDashboard, isReadOnly, hasDashboardAccess],
  );

  const formatDashboardDateRangeLabel = useCallback(
    (dashboardDateRange: DashboardDateRange.Data): string => {
      if (dashboardDateRange?.type)
        return DASHBOARD_DATE_RANGE_LABEL[dashboardDateRange.type];

      if (dashboardDateRange?.startDate && dashboardDateRange?.endDate) {
        const [formattedStartDate, formattedEndDate] = [
          formatToMonthFullYearDate(new Date(dashboardDateRange.startDate)),
          formatToMonthFullYearDate(new Date(dashboardDateRange.endDate)),
        ];

        return t("page.dashboard.label.dashboard_date_range", {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      }

      return "";
    },
    [t],
  );

  const dashboardDateRangeOptions = useMemo<SelectOption[]>(
    () =>
      sortedDashboardDateRanges.map((dashboardDateRange) => ({
        value: dashboardDateRange.id,
        label: formatDashboardDateRangeLabel(dashboardDateRange),
        ...(dashboardDateRange.category === "custom" && !isReadOnly
          ? {
              renderActions: () => (
                <DashboardDateRangeActions
                  dashboardDateRange={dashboardDateRange}
                />
              ),
            }
          : {}),
      })),
    [formatDashboardDateRangeLabel, sortedDashboardDateRanges, isReadOnly],
  );

  useEffect(() => {
    if (!defaultDashboardDateRange) return;

    const dashboardDateRange = dashboardDateRanges.find(
      ({ id }) => dashboardDateRangeId === id,
    );

    if (!dashboardDateRange)
      selectDashboardDateRangeIdHandler(defaultDashboardDateRange.id);
  }, [
    dashboardDateRanges,
    dashboardDateRangeId,
    defaultDashboardDateRange,
    selectDashboardDateRangeIdHandler,
  ]);

  const createDashboardDateRangeHandler = useCallback(
    (value: DashboardDateRange.Data["id"], callback: () => void): void => {
      selectDashboardDateRangeIdHandler(value);

      callback();
    },
    [selectDashboardDateRangeIdHandler],
  );

  const renderCreateDashboardDateRangeForm = useCallback(
    (callback: () => void): JSX.Element | null => {
      if (!isAddDashboardDateRangeButtonShown) return null;

      return (
        <CreateDashboardDateRangeForm
          trackersCollectionId={trackersCollectionId}
          submitHandler={(value) =>
            createDashboardDateRangeHandler(value, callback)
          }
        />
      );
    },
    [
      trackersCollectionId,
      createDashboardDateRangeHandler,
      isAddDashboardDateRangeButtonShown,
    ],
  );

  return (
    <div className={styles.wrapper}>
      <Select
        hasFilter={false}
        icon="CalendarRange"
        value={dashboardDateRangeId}
        openingDirection="bottom-end"
        inputClassName={styles.input}
        selectClassName={styles.select}
        dropdownClassName={styles.dropdown}
        options={dashboardDateRangeOptions}
        optionsClassName={styles.optionsWrapper}
        renderFooter={renderCreateDashboardDateRangeForm}
        changeHandler={selectDashboardDateRangeIdHandler}
      />
    </div>
  );
};
