import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./DuplicateDashboardModal.module.scss";
import Context from "src/context";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { Alert, Form, Input, Label, Translation } from "src/components";
import { useElementFocus, useModal, useTemporaryErrors } from "src/hooks";
import {
  selectDashboardById,
  selectTrackersCollectionTrackers,
} from "src/store/selectors";
import {
  triggerGtmEvent,
  removeExtraSpaces,
  showToastNotification,
} from "src/utils";
import {
  ROUTES,
  DASHBOARD_INPUT_LIMIT,
  DASHBOARD_DEFAULT_VISIBILITY,
} from "src/constants";
import {
  createDashboard,
  createTrackersCollection,
  duplicateDashboardTrackers,
} from "src/store/actions";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

const InputWithError = withError(Input);

type Props = {
  dashboardId: Dashboard.Data["id"];
};

export const DuplicateDashboardModal: FC<Props> = ({
  dashboardId: originalDashboardId,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const [ref, setFocus] = useElementFocus();

  const { isUserAuthenticated } = useContext(Context);

  const { errors, setErrors } = useTemporaryErrors(3000);

  const originalDashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, originalDashboardId),
  );

  const originalTrackers = useSelector((state: Store.RootState) =>
    selectTrackersCollectionTrackers(state, originalDashboardId),
  );

  const defaultDashboard = useMemo<Pick<Dashboard.Data, "name">>(() => {
    if (!originalDashboard) return { name: "" };

    return { name: originalDashboard.name };
  }, [originalDashboard]);

  const [dashboard, setDashboard] =
    useImmer<Pick<Dashboard.Data, "name">>(defaultDashboard);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isDisabled = useMemo<boolean>(() => isLoading, [isLoading]);

  useEffect(() => setFocus(), [setFocus]);

  useEffect(() => {
    if (!isUserAuthenticated) closeModal();
  }, [isUserAuthenticated, closeModal]);

  const onNameChange = (value: Dashboard.Data["name"]): void =>
    setDashboard((draft) => {
      draft.name = value;
    });

  const onSubmit = async (): Promise<void> => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    try {
      setLoadingStatus("loading");

      // Duplicate trackers ---->
      const trackers = await dispatch(
        duplicateDashboardTrackers(originalDashboardId),
      ).unwrap();

      // Create trackers collection ---->
      const { id: trackersCollectionId } = await dispatch(
        createTrackersCollection({
          name: `${removeExtraSpaces(dashboard.name)} Collection`,
          trackerIds: trackers.map(({ id }) => id),
        }),
      ).unwrap();

      // Create dashboard based on tracker collection ---->
      const { id: dashboardId } = await dispatch(
        createDashboard({
          name: removeExtraSpaces(dashboard.name),
          trackersCollectionId,
          visibility: DASHBOARD_DEFAULT_VISIBILITY,
          originalDashboardId,
        }),
      ).unwrap();

      setLoadingStatus("succeeded");

      history.push(`${ROUTES.dashboardsHomePage}/${dashboardId}`);

      triggerGtmEvent("SharedDashboardSave", {
        dashboardId: originalDashboardId,
      });

      closeModal();
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      setLoadingStatus("failed");
    }
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const { name } = dashboard;

    if (!name.trim().length)
      validationErrors.name = t(
        "component.modal.duplicate_dashboard.form.validation.name_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      type="success"
      acceptButton={{
        onClick: onSubmit,
        disabled: isDisabled,
        text: t("component.modal.duplicate_dashboard.button.submit"),
      }}
      cancelButton={{
        onClick: closeModal,
        text: t("component.modal.duplicate_dashboard.button.cancel"),
      }}
      title={t("component.modal.duplicate_dashboard.title")}
      isLoading={isLoading}
    >
      <Form
        className={styles.formWrapper}
        onSubmit={onSubmit}
        disabled={isDisabled}
      >
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.duplicate_dashboard.form.label.name")}
          />
          <InputWithError
            ref={ref}
            error={errors.name}
            value={dashboard.name}
            changeHandler={onNameChange}
            characterLimit={DASHBOARD_INPUT_LIMIT}
            placeholder={t(
              "component.modal.duplicate_dashboard.form.placeholder.name",
            )}
          />
        </div>
        <div className={styles.alertWrapper}>
          <Alert
            type="info"
            message={
              <Translation
                i18nKey="component.modal.duplicate_dashboard.status.warning.data_update"
                values={{ count: originalTrackers.length }}
              />
            }
          />
        </div>
      </Form>
    </ConfirmModal>
  );
};
