import { useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useLocationState } from "src/hooks";
import { Translation } from "src/components";
import { showToastNotification } from "src/utils";
import {
  selectTrackersEntities,
  selectTrackersCollectionTrackers,
  selectTrackersCollectionsTrackersLimit,
} from "src/store/selectors";

export const useUsedTrackers = (
  trackersCollectionId: TrackersCollection.Data["id"],
): [
  Tracker.Data[],
  (trackerId: Tracker.Data["id"]) => void,
  (trackerId: Tracker.Data["id"]) => void,
  boolean,
] => {
  const history = useHistory();

  const locationState = useLocationState();

  const trackers = useSelector(selectTrackersEntities);

  const dashboardTrackers = useSelector((state: Store.RootState) =>
    selectTrackersCollectionTrackers(state, trackersCollectionId),
  );

  const { trackersCollectionsTrackersLimit } = useSelector(
    selectTrackersCollectionsTrackersLimit,
  );

  const [usedTrackers, setUsedTrackers] =
    useState<Tracker.Data[]>(dashboardTrackers);

  const isUsedTrackersChanged = useMemo<boolean>(() => {
    const initialDashboardTrackerIds = dashboardTrackers
      .map(({ id }) => id)
      .sort((a, b) => a.localeCompare(b));

    const currentDashboardTrackerIds = usedTrackers
      .map(({ id }) => id)
      .sort((a, b) => a.localeCompare(b));

    return !isEqual(initialDashboardTrackerIds, currentDashboardTrackerIds);
  }, [dashboardTrackers, usedTrackers]);

  const addUsedTracker = (trackerId: Tracker.Data["id"]) => {
    const tracker = trackers[trackerId];

    if (!tracker) return;

    const isTrackerAdded = usedTrackers.some(({ id }) => id === trackerId);

    if (isTrackerAdded) return;

    if (usedTrackers.length >= trackersCollectionsTrackersLimit)
      return showToastNotification({
        id: "trackers-collection-tracker-limit",
        type: "warning",
        text: (
          <Translation
            i18nKey="trackers_collection.status.warning.trackers_collection_limit"
            values={{ count: trackersCollectionsTrackersLimit }}
          />
        ),
      });

    setUsedTrackers((state) => [...state, tracker]);
  };

  const removeUsedTracker = (trackerId: Tracker.Data["id"]) => {
    setUsedTrackers((state) => state.filter(({ id }) => id !== trackerId));
  };

  useEffect(() => {
    const { topicIds: trackerIds = [], ...cleanLocationState } = locationState;

    if (!trackerIds.length) return;

    setUsedTrackers((state) => {
      const updatedState = [...state];

      for (const trackerId of trackerIds) {
        const tracker = trackers[trackerId];

        if (!tracker) continue;

        if (updatedState.length >= trackersCollectionsTrackersLimit)
          return updatedState;

        const isTrackerAdded = state.some(({ id }) => id === trackerId);

        if (!isTrackerAdded) updatedState.push(tracker);
      }

      return updatedState;
    });

    history.replace({ state: cleanLocationState });
  }, [history, locationState, trackers, trackersCollectionsTrackersLimit]);

  return [
    usedTrackers,
    addUsedTracker,
    removeUsedTracker,
    isUsedTrackersChanged,
  ];
};
