import {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  ForwardedRef,
} from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./MenuDropdown.module.scss";
import { Button } from "src/components";
import { ThreeDots } from "src/assets/icons";
import { useOutsideClickHandler } from "src/hooks";
import { getIconByName, isAppIconTypeGuard } from "src/utils";

// Inner imports
import { MenuDropdownSubmenu } from "./components";
import { getClientParams, getElementParams } from "./utils";
import type { DropdownDirection, MenuDropdownProps } from "./types";

export const MenuDropdown = memo(
  forwardRef(
    (
      {
        options,
        children,
        buttonContent,
        className = "",
        dropdownDirection,
        buttonClassName = "",
        dropdownClassName = "",
        isEventPropagationStopped,
        ...props
      }: MenuDropdownProps,
      ref: ForwardedRef<HTMLElement>,
    ) => {
      const { t } = useTranslation();

      const dropdownRef = useRef<HTMLDivElement>(null);

      const dropdownButtonRef = useRef<HTMLDivElement>(null);

      const [isOpen, setIsOpen] = useState<boolean>(false);

      const [openingDirection, setOpeningDirection] =
        useState<DropdownDirection>(
          dropdownDirection ?? { x: undefined, y: undefined },
        );

      const toggleOpenMenu = useCallback(
        (): void => setIsOpen((state) => !state),
        [],
      );

      const MenuDropdownButton = useMemo<JSX.Element>(() => {
        if (children) return <div onClick={toggleOpenMenu}>{children}</div>;

        if (buttonContent)
          return (
            <Button
              buttonSize="small"
              onClick={toggleOpenMenu}
              buttonStyle="transparent"
              className={cx(styles.button, buttonClassName)}
            >
              {buttonContent}
            </Button>
          );

        return (
          <Button
            buttonSize="small"
            onClick={toggleOpenMenu}
            buttonStyle="transparent"
            className={cx(styles.button, buttonClassName)}
          >
            <ThreeDots
              className={cx(
                styles.buttonIcon,
                isOpen ? styles.buttonIconRotate : "",
              )}
            />
          </Button>
        );
      }, [buttonClassName, buttonContent, children, isOpen, toggleOpenMenu]);

      useEffect(() => {
        if (dropdownDirection) return;

        const calculateOpeningDirection = () => {
          const {
            element: parentElement,
            right: parentRight,
            bottom: parentBottom,
          } = getElementParams(ref);

          const {
            height: dropdownHeight,
            width: dropdownWidth,
            right: dropdownRight,
            bottom: dropdownBottom,
          } = getElementParams(dropdownRef);

          const {
            height: dropdownButtonHeight,
            width: dropdownButtonWidth,
            right: dropdownButtonRight,
            bottom: dropdownButtonBottom,
          } = getElementParams(dropdownButtonRef);

          const { height, width } = getClientParams();

          if (!dropdownHeight || !dropdownWidth)
            return setOpeningDirection({
              x: undefined,
              y: undefined,
            });

          if (parentElement)
            return setOpeningDirection({
              x: parentRight > dropdownRight ? "right" : "left",
              y: parentBottom > dropdownBottom ? "down" : "up",
            });

          return setOpeningDirection({
            x:
              width > dropdownButtonRight + dropdownButtonWidth
                ? "right"
                : "left",
            y:
              height > dropdownButtonBottom + dropdownButtonHeight
                ? "down"
                : "up",
          });
        };

        calculateOpeningDirection();

        window.addEventListener("resize", calculateOpeningDirection);

        return () =>
          window.removeEventListener("resize", calculateOpeningDirection);
      }, [isOpen, dropdownDirection, ref]);

      useOutsideClickHandler(dropdownButtonRef, () => setIsOpen(false));

      return (
        <div
          ref={dropdownButtonRef}
          className={cx(styles.menuDropdown, className)}
          onClick={(e) => isEventPropagationStopped && e.stopPropagation()}
          {...props}
        >
          {MenuDropdownButton}
          {isOpen && (
            <div
              className={cx(
                styles.dropdown,
                styles[openingDirection?.x || ""],
                styles[openingDirection?.y || ""],
                dropdownClassName,
              )}
              ref={dropdownRef}
            >
              {Boolean(options.length) ? (
                options.map((option, i) => {
                  const subOptionsLength = option.options?.length || 0;

                  const {
                    icon,
                    label,
                    onClick,
                    responsivenessType = "",
                  } = option;

                  if (subOptionsLength)
                    return (
                      <MenuDropdownSubmenu
                        key={i}
                        option={option}
                        direction={dropdownDirection?.x}
                        callback={() => setIsOpen(false)}
                        className={cx(
                          styles.option,
                          styles[responsivenessType],
                        )}
                      />
                    );

                  const Icon = isAppIconTypeGuard(icon)
                    ? getIconByName(icon)
                    : icon;

                  return (
                    <button
                      className={cx(styles.option, styles[responsivenessType])}
                      onClick={(e) => {
                        onClick?.(e);
                        setIsOpen(false);
                      }}
                      key={i}
                    >
                      {Icon && <div className={styles.icon}>{Icon}</div>}
                      <span className={styles.label} title={label}>
                        {label}
                      </span>
                    </button>
                  );
                })
              ) : (
                <div className={styles.noOptions}>
                  <span className={styles.label}>{t("no_options")}</span>
                </div>
              )}
            </div>
          )}
        </div>
      );
    },
  ),
);
