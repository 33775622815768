import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { isKeywordsDataSourceTypeGuard } from "src/utils";

export const selectApplicationInfo = createDraftSafeSelector(
  ({ application }: Store.RootState) => application,
  (application) => application,
);

export const selectKeywordsDataSourcesLatestDataDate = createDraftSafeSelector(
  (
    state: Store.RootState,
    selectedKeywordsDataSources: Search.KeywordsDataSource[],
  ): [
    ReturnType<typeof selectApplicationInfo>,
    Search.KeywordsDataSource[],
  ] => [selectApplicationInfo(state), selectedKeywordsDataSources],
  ([{ keywordsSettings }, selectedKeywordsDataSources]) => {
    const keywordsDataSourceDates = new Set<number>();

    for (const keywordsDataSource in keywordsSettings) {
      if (!isKeywordsDataSourceTypeGuard(keywordsDataSource)) continue;

      if (!selectedKeywordsDataSources.includes(keywordsDataSource)) continue;

      const { date } = keywordsSettings[keywordsDataSource];

      if (!date) continue;

      const formattedDate = new Date(date).valueOf();

      keywordsDataSourceDates.add(formattedDate);
    }

    const minDate = Math.min(...Array.from(keywordsDataSourceDates));

    if (!minDate) return null;

    return new Date(minDate).toISOString();
  },
);
