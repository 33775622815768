import { FC, useMemo } from "react";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./ShareDashboardModal.module.scss";
import { useModal } from "src/hooks";
import { ROUTES } from "src/constants";
import { LinkOutline } from "src/assets/icons";
import { Button, Form, Input } from "src/components";
import { showToastNotification, triggerGtmEvent } from "src/utils";
import { selectAvailableDashboardById } from "src/store/selectors";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

type Props = {
  dashboardId: Dashboard.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const ShareDashboardModal: FC<Props> = ({
  dashboardId,
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId),
  );

  const dashboardLink = useMemo<string>(() => {
    if (!dashboardId) return "";

    const queryParams = new URLSearchParams({
      dateRangeId: dashboardDateRangeId,
    }).toString();

    return `${window.location.origin}${ROUTES.shareDashboard}/${dashboardId}?${queryParams}`;
  }, [dashboardDateRangeId, dashboardId]);

  const onSubmit = (): void => {
    if (!dashboardLink) return;

    copy(dashboardLink);

    triggerGtmEvent("DashboardCopyShareLink", { dashboardId });

    showToastNotification({
      id: "share-dashboard-link",
      type: "success",
      text: t("component.modal.share_dashboard.status.success.link_copied"),
    });
  };

  return (
    <ConfirmModal
      type="info"
      cancelButton={{
        text: t("component.modal.share_dashboard.button.cancel"),
        onClick: closeModal,
      }}
      title={t("component.modal.share_dashboard.title", {
        name: dashboard?.name || "",
      })}
    >
      <div className={styles.wrapper}>
        <Form className={styles.form} onSubmit={onSubmit}>
          <Input value={dashboardLink} readOnly />
          <Button className={styles.button} type="submit" buttonSize="medium">
            <LinkOutline />
            <span>{t("component.modal.share_dashboard.button.submit")}</span>
          </Button>
        </Form>
        <div className={styles.text}>
          {t("component.modal.share_dashboard.body")}
        </div>
      </div>
    </ConfirmModal>
  );
};
