import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactGridLayout, { Responsive, WidthProvider } from "react-grid-layout";

import styles from "./DashboardBody.module.scss";
import { WidgetTile } from "src/features";
import { useAppDispatch } from "src/store";
import { showDevelopmentError } from "src/utils";
import { updateDashboard } from "src/store/actions";
import { DASHBOARDS_COLUMN_SIZES } from "src/constants";
import { selectDashboardById } from "src/store/selectors";

// Inner imports
import { calculateUpdatedLayouts } from "../../utils";
import {
  useDashboardLayouts,
  useScrollToExtendedWidget,
  useDashboardGridRowHeight,
  useDashboardActiveWidgetIds,
} from "../../hooks";

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
  isReadOnly: boolean;
  expandedWidgetId: Widget.IdType | null;
  activeTrackerIds: Tracker.Data["id"][];
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  setExpandedWidgetId: (value: Widget.IdType | null) => void;
};

export const DashboardBody: FC<Props> = memo(
  ({
    isReadOnly,
    activeTrackerIds,
    expandedWidgetId,
    setExpandedWidgetId,
    dashboardDateRangeId,
    trackersCollectionId,
  }) => {
    const dispatch = useAppDispatch();

    const dashboardBodyRef = useRef<HTMLDivElement>(null);

    const [layoutUpdateStatus, setLayoutUpdateStatus] =
      useState<LoadingStatus>("idle");

    const dashboard = useSelector((state: Store.RootState) =>
      selectDashboardById(state, trackersCollectionId),
    );

    const { layouts, tiles, layoutView } = useMemo<
      Pick<Dashboard.Data, "layouts" | "tiles" | "layoutView">
    >(
      () => ({
        tiles: dashboard?.tiles || {},
        layoutView: dashboard?.layoutView || "full",
        layouts: dashboard?.layouts || { small: [], medium: [], large: [] },
      }),
      [dashboard?.layoutView, dashboard?.layouts, dashboard?.tiles],
    );

    const dashboardLayouts = useDashboardLayouts(
      layouts,
      tiles,
      layoutView,
      expandedWidgetId,
    );

    const dashboardWidgetIds = useDashboardActiveWidgetIds(
      tiles,
      layoutView,
      expandedWidgetId,
    );

    useScrollToExtendedWidget(expandedWidgetId, dashboardBodyRef);

    const rowHeight = useDashboardGridRowHeight(expandedWidgetId);

    const onLayoutChange = useCallback(
      (
        currentLayout: ReactGridLayout.Layout[],
        allLayouts: ReactGridLayout.Layouts,
      ): void => {
        if (
          isReadOnly ||
          expandedWidgetId ||
          layoutUpdateStatus === "loading" ||
          layoutView !== "full"
        )
          return;

        setLayoutUpdateStatus("loading");

        const updatedLayouts = calculateUpdatedLayouts(
          allLayouts,
          layouts,
          tiles,
        );

        if (!Object.keys(updatedLayouts).length)
          return setLayoutUpdateStatus("succeeded");

        dispatch(
          updateDashboard({
            id: trackersCollectionId,
            changes: { layouts: { ...layouts, ...updatedLayouts } },
          }),
        )
          .unwrap()
          .then(() => setLayoutUpdateStatus("succeeded"))
          .catch((error) => {
            showDevelopmentError({
              error,
              additionalTexts: [`Error in dashboard "onLayoutChange" handler`],
            });

            setLayoutUpdateStatus("failed");
          });
      },
      [
        tiles,
        layouts,
        dispatch,
        isReadOnly,
        layoutView,
        expandedWidgetId,
        layoutUpdateStatus,
        trackersCollectionId,
      ],
    );

    if (!dashboard) return null;

    return (
      <div ref={dashboardBodyRef} className={styles.dashboardBody}>
        <ResponsiveGridLayout
          className={styles.responsiveLayout}
          breakpoints={{ small: 0, medium: 768, large: 1200 }}
          cols={DASHBOARDS_COLUMN_SIZES}
          containerPadding={[0, 0]}
          margin={[20, 20]}
          rowHeight={rowHeight}
          layouts={dashboardLayouts}
          isDraggable={false}
          isDroppable={false}
          isResizable={false}
          useCSSTransforms={false}
          onLayoutChange={onLayoutChange}
        >
          {dashboardWidgetIds.map((widgetId) => (
            <div
              key={widgetId}
              id={dashboardDateRangeId}
              className={styles.widgetTileWrapper}
            >
              <WidgetTile
                widgetId={widgetId}
                isReadOnly={isReadOnly}
                trackerIds={activeTrackerIds}
                expandedWidgetId={expandedWidgetId}
                setExpandedWidgetId={setExpandedWidgetId}
                trackersCollectionId={trackersCollectionId}
                dashboardDateRangeId={dashboardDateRangeId}
              />
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    );
  },
);
