import * as yup from "yup";

import * as userSchemas from "../user/userSchema";

const enterpriseDashboardSchema = yup
  .object({
    id: yup.string().default(""),
    name: yup.string().default(""),
  })
  .noUnknown()
  .required();

export const companySchema = yup
  .object({
    name: yup.string().default(""),
    whiteLabel: yup.string().required(),
    subscriptionPlanId: yup.string().required(),

    countryCode: yup.string().default(""),

    enterpriseDashboards: yup.array(enterpriseDashboardSchema).default([]),

    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
    subscriptionPlanUpdatedAt: yup.string().default(""),
    subscriptionPlanExpirationDate: yup.string().nullable().default(""),

    customTrackersLimit: yup.number().nullable().default(null),

    stripeCustomerId: yup.string().nullable().default(null),
    stripeSubscriptionId: yup.string().nullable().default(null),
  })
  .noUnknown()
  .required();

export const externalCompanySchema = companySchema.pick([
  "subscriptionPlanId",
  "whiteLabel",
]);

export const memberSchema = userSchemas.userSchema.pick([
  "firstName",
  "lastName",
  "companyRole",
  "lastActiveAt",
]);
