import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "src/store";
import { ConfirmModal } from "src/features";
import { showToastNotification } from "src/utils";
import { useGlobalPreloader, useModal } from "src/hooks";
import { removeDashboard, removeTrackersCollection } from "src/store/actions";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const ConfirmDashboardDeletionModal: FC<Props> = ({
  trackersCollectionId,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const { closeModal } = useModal();

  const onConfirmDeleteDashboard = useCallback(async () => {
    showGlobalPreloader();
    try {
      await dispatch(removeDashboard(trackersCollectionId)).unwrap();

      await dispatch(removeTrackersCollection(trackersCollectionId)).unwrap();

      showToastNotification({
        type: "success",
        text: t(
          "component.modal.delete_dashboard.status.success.dashboard_deleted",
        ),
      });
    } catch (error) {
      console.error(error);

      showToastNotification({
        type: "error",
        text: t("common.error.server_error_reload"),
      });
    } finally {
      closeModal();

      hideGlobalPreloader();
    }
  }, [
    t,
    dispatch,
    closeModal,
    hideGlobalPreloader,
    showGlobalPreloader,
    trackersCollectionId,
  ]);

  return (
    <ConfirmModal
      type="error"
      acceptButton={{
        text: t("component.modal.delete_dashboard.button.submit"),
        buttonType: "error",
        onClick: onConfirmDeleteDashboard,
      }}
      cancelButton={{
        text: t("component.modal.delete_dashboard.button.cancel"),
        onClick: closeModal,
      }}
      title={t("component.modal.delete_dashboard.title")}
    >
      <span>{t("component.modal.delete_dashboard.body")}</span>
    </ConfirmModal>
  );
};
